import {
  Layout,
  DollarSign,
  BookOpen,
  Users,
  Coffee,
  Video,
  BarChart,
} from "react-feather";

const adminSection = [
  {
    icon: Layout,
    href: "/admin/dashboard",
    title: "Dashboard",
  },
  {
    icon: BarChart,
    href: "/admin/analytics",
    title: "Analytics",
  },
  {
    href: "/admin",
    title: "Users",
    icon: Users,
    children: [
      {
        href: "/admin/all-users",
        title: "User List",
        // icon: List,
      },
      {
        href: "/admin/create-user",
        title: "Create User",
        // icon: Plus,
      },
    ],
  },
  {
    href: "/admin",
    title: "Virtual Tours",
    icon: Video,
    children: [
      {
        href: "/admin/virtual-tours",
        title: "Tour List",
        // icon: List,
      },
      {
        href: "/admin/upload-tours",
        title: "Create Virtual Tour",
        // icon: Plus,
      },
    ],
  },

  {
    href: "/admin",
    title: "Our Works",
    icon: Coffee,
    children: [
      {
        href: "/admin/our-works",
        title: "Our Works List",
        // icon: List,
      },
      {
        href: "/admin/create-our-work",
        title: "Create Our Work",
        // icon: Plus,
      },
    ],
  },

  {
    icon: DollarSign,
    href: "/admin",
    title: "ThisCVR Bank Details",
    children: [
      {
        href: "/admin/manage-banks",
        title: "Bank List",
        // icon: List,
      },
      {
        href: "/admin/add-new-bank",
        title: "Add New Bank",
        // icon: Plus,
      },
    ],
  },
  {
    href: "/admin",
    title: "Invoice",
    icon: BookOpen,
    children: [
      {
        href: "/admin/invoices",
        title: "Invoice List",
        // icon: List,
      },
      {
        href: "/admin/create-new-invoice",
        title: "Add New Invoice",
        // icon: Plus,
      },
    ],
  },
  // {
  //   icon: Layout,
  //   href: "/auth/sign-out",
  //   title: "Logout",
  // },
  // {
  //   href: "/pages",
  //   icon: Layout,
  //   title: "Settings",
  //   children: [
  //     {
  //       href: "/admin/sign-up",
  //       title: "Admin Sign Up",
  //     },
  //   ],
  // },
];

// const elementsSection = [
//   {
//     href: "/components",
//     icon: Grid,
//     title: "Components",
//     children: [
//       {
//         href: "/components/alerts",
//         title: "Alerts",
//       },
//       {
//         href: "/components/accordion",
//         title: "Accordion",
//       },
//       {
//         href: "/components/avatars",
//         title: "Avatars",
//       },
//       {
//         href: "/components/badges",
//         title: "Badges",
//       },
//       {
//         href: "/components/buttons",
//         title: "Buttons",
//       },
//       {
//         href: "/components/cards",
//         title: "Cards",
//       },
//       {
//         href: "/components/chips",
//         title: "Chips",
//       },
//       {
//         href: "/components/dialogs",
//         title: "Dialogs",
//       },
//       {
//         href: "/components/lists",
//         title: "Lists",
//       },
//       {
//         href: "/components/menus",
//         title: "Menus",
//       },
//       {
//         href: "/components/pagination",
//         title: "Pagination",
//       },
//       {
//         href: "/components/progress",
//         title: "Progress",
//       },
//       {
//         href: "/components/snackbars",
//         title: "Snackbars",
//       },
//       {
//         href: "/components/tooltips",
//         title: "Tooltips",
//       },
//     ],
//   },
//   {
//     href: "/charts",
//     icon: PieChart,
//     title: "Charts",
//     children: [
//       {
//         href: "/charts/chartjs",
//         title: "Chart.js",
//       },
//       {
//         href: "/charts/apexcharts",
//         title: "ApexCharts",
//       },
//     ],
//   },
//   {
//     href: "/forms",
//     icon: CheckSquare,
//     title: "Forms",
//     children: [
//       {
//         href: "/forms/pickers",
//         title: "Pickers",
//       },
//       {
//         href: "/forms/selection-controls",
//         title: "Selection Controls",
//       },
//       {
//         href: "/forms/selects",
//         title: "Selects",
//       },
//       {
//         href: "/forms/text-fields",
//         title: "Text Fields",
//       },
//       {
//         href: "/forms/editors",
//         title: "Editors",
//       },
//       {
//         href: "/forms/formik",
//         title: "Formik",
//       },
//     ],
//   },
//   {
//     href: "/tables",
//     icon: List,
//     title: "Tables",
//     children: [
//       {
//         href: "/tables/simple-table",
//         title: "Simple Table",
//       },
//       {
//         href: "/tables/advanced-table",
//         title: "Advanced Table",
//       },
//       {
//         href: "/tables/data-grid",
//         title: "Data Grid",
//       },
//     ],
//   },
//   {
//     href: "/icons",
//     icon: Heart,
//     title: "Icons",
//     children: [
//       {
//         href: "/icons/material-icons",
//         title: "Material Icons",
//       },
//       {
//         href: "/icons/feather-icons",
//         title: "Feather Icons",
//       },
//     ],
//   },
//   {
//     href: "/maps",
//     icon: Map,
//     title: "Maps",
//     children: [
//       {
//         href: "/maps/google-maps",
//         title: "Google Maps",
//       },
//       {
//         href: "/maps/vector-maps",
//         title: "Vector Maps",
//       },

//     ],
//   },
// ];

// const docsSection = [
//   {
//     href: "/documentation/welcome",
//     icon: BookOpen,
//     title: "Documentation",
//   },
//   {
//     href: "/changelog",
//     icon: List,
//     title: "Changelog",
//     badge: "v4.1.1",
//   },
// ];

const navItems = [
  {
    title: "Welcome to Admin Dashboard",
    pages: adminSection,
  },
  // {
  //   title: "Elements",
  //   pages: elementsSection,
  // },
  // {
  //   title: "Mira Pro",
  //   pages: docsSection,
  // },
];

export default navItems;
