import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";
import { isValidToken, setSession } from "./utils/jwt";
import ReactGA from 'react-ga';
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";
// import useAppDispatch from "./hooks/useAppDispatch";
// import { initialize, selectJWT } from "./redux/slices/jwt";
import { createBrowserHistory } from 'history';

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const history = createBrowserHistory();

  //Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.initialize('UA-243866673-1');
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  useEffect(() => {
    ReactGA.initialize('UA-243866673-1');
    ReactGA.pageview(window.location.pathname);
    const accessToken = window.localStorage.getItem("accessToken");
    // console.log(accessToken);
    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
    }
  });
  const content = useRoutes(routes);
  const { theme } = useTheme();
  // const dispatch = useAppDispatch();

  return (
    <>
      <CacheProvider value={emotionCache}>
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | ThisCVR"
            defaultTitle="ThisCVR Dashboard"
          />
          <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MuiThemeProvider theme={createTheme(theme)}>
                <AuthProvider>{content}</AuthProvider>
              </MuiThemeProvider>
            </LocalizationProvider>
          </Provider>
        </HelmetProvider>
      </CacheProvider>
      {/* <ToastContainer /> */}
    </>
  );
}

export default App;
