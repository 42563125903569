import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, Link as LinkRRD } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import {
    Grid,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    FormControlLabel,
    Switch,
    Button,
    Box,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {
    selectTour,
    fetchAllAdminTours,
} from "../../../redux/slices/tour";
import ThreeDotMenu from "../../../components/menu/ThreeDotMenu";
import http from "../../../utils/http";
import Swal from "sweetalert2";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;


const Image = styled.img`
  width: auto;
  height: 40px;
  display: block;
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
        el,
        index,
    }));
    stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
}

const headCells = [
    {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Title",
    },
    {
        id: "coverImage",
        numeric: false,
        disablePadding: false,
        label: "Cover Image",
    },
    {
        id: "shortDescription",
        numeric: false,
        disablePadding: false,
        label: "Description",
    },
    {
        id: "link",
        numeric: false,
        disablePadding: false,
        label: "Tour",
    },
    {
        id: "upload",
        numeric: false,
        disablePadding: false,
        label: "Bucket",
    },
    {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Actions",
        menus: [
            // { label: "Details", link: "/" },
            { label: "Edit", link: "/" },
            { label: "Delete", link: "/" },
        ],
    },
];

const populateMenusLink = ([menus, edit, delFunc]) => {
    const newMenus = [{ ...menus[0] }, { ...menus[1] }];
    newMenus[0].link = edit;
    newMenus[1].delete = delFunc;
    return newMenus;
};

const EnhancedTableHead = (props) => {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        // padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const EnhancedTableToolbar = (props) => {
    // Here was 'let'
    const { numSelected } = props;

    return (
        <Toolbar>
            <div>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle" width="100px">
                        Tours List
                    </Typography>
                )}
            </div>
            <Spacer />

        </Toolbar>
    );
};

function EnhancedTable({ data: adminTours }) {
    const appDispatch = useAppDispatch();
    //console.log("data", tours);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [toursList, setToursList] = React.useState([]);

    useEffect(() => {
        setToursList(adminTours);
        //console.log("RENDERING");
    }, [adminTours]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, toursList.length - page * rowsPerPage);

    const deleteAdminTour = async (id) => {
        if (!id) return;
        try {
            await http.delete(`/tours/adminTour/${id}`);
            const tourDeleted = toursList.filter((tour) => tour._id !== id);
            setToursList(tourDeleted);
        } catch (error) {
            //console.log("error");
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to delete VT",
            });
        }
    };

    return (
        <div>
            <Paper>
                {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }} pt={2} pr={2}>
          <RRDLink style={{ textDecoration: "none" }} to="/admin/upload-tours">
            <Button variant="contained" color="primary" component="span">
              Upload VT
            </Button>
          </RRDLink>
        </Box> */}
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={toursList.length}
                        />
                        <TableBody>
                            {toursList.length ? (
                                stableSort(toursList, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row._id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow hover key={index}>
                                                {headCells.map((cell, index) => (
                                                    <TableCell
                                                        key={index}
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        // padding={!index ? "none" : "normal"}
                                                        align={cell.numeric ? "right" : "left"}
                                                    >
                                                        {cell.id === "coverImage" && (
                                                            <Image
                                                                alt={row.title}
                                                                src={row.coverImage}
                                                            />
                                                        )}
                                                        {cell.id === "link" && (
                                                            <Button>
                                                                <a
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        color: "white",
                                                                        fontWeight: "800",
                                                                    }}
                                                                    href={row.tourLink}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    Visit
                                                                </a>
                                                            </Button>
                                                        )}
                                                        {cell.id === "upload" && (
                                                            <Button>
                                                                <a
                                                                    style={{
                                                                        textDecoration: "none",
                                                                        color: "white",
                                                                        fontWeight: "800",
                                                                    }}
                                                                    href={`https://s3.console.aws.amazon.com/s3/buckets/virtual-tours.thiscvr.com?region=us-east-1&tab=objects`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    Upload
                                                                </a>
                                                            </Button>
                                                        )}
                                                        <Typography title={row.shortDescription} textTransform="capitalize" overflow="hidden" maxHeight={60}>
                                                            {cell.id === "shortDescription" && row.shortDescription}
                                                        </Typography>
                                                        <Typography textTransform="capitalize">
                                                            {cell.id === "title" && row[cell.id]}
                                                        </Typography>
                                                        {cell.id === "action" && (
                                                            <ThreeDotMenu
                                                                menus={populateMenusLink([
                                                                    cell.menus,
                                                                    `/admin/admin-vt/edit/${row._id}`,
                                                                    () => {
                                                                        deleteAdminTour(row._id);
                                                                        appDispatch(fetchAllAdminTours());
                                                                    },
                                                                ])}
                                                            />
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    })
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                                // <Grid
                                //   container
                                //   direction="row"
                                //   justifyContent="center"
                                //   alignItems="center"
                                //   style={{ width: "100%" }}
                                // >
                                //   <Grid item xs>
                                //     <Typography
                                //       variant="h5"
                                //       align="center"
                                //       fluid
                                //       width={"full"}
                                //       noWrap
                                //     >
                                //       {/* No Virtual Tours Available */}
                                //     </Typography>
                                //   </Grid>
                                // </Grid>
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer >
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={toursList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper >
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </div >
    );
}

function OurWorks() {
    const { adminTours } = useSelector(selectTour); //fetching the list of tours from redux store

    const appDispatch = useAppDispatch();

    useEffect(() => {
        appDispatch(fetchAllAdminTours());
    }, [appDispatch]);
    return (
        <React.Fragment>
            <Helmet title="Manage Our Works" />
            <Toolbar>
                <Grid justifyContent="space-between" container spacing={10} mb={6}>
                    <Grid item>
                        <Typography variant="h3" gutterBottom display="inline">
                            Manage Our Works
                        </Typography>

                        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                            <LinkRRD
                                component={NavLink}
                                style={{ color: "#407ad6", textDecoration: "none" }}
                                to="/"
                            >
                                Dashboard
                            </LinkRRD>
                            <Typography>Manege Our Works</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <div>
                            <LinkRRD to="/admin/create-our-work"
                                style={{ textDecoration: "none" }}>
                                <Button variant="contained" color="primary">
                                    <AddIcon />
                                    Add Our Work
                                </Button>
                            </LinkRRD>
                        </div>
                    </Grid>
                </Grid>
            </Toolbar>
            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <EnhancedTable data={adminTours} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default OurWorks;
