import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AdminGuard({ children }) {
  const { isAuthenticated, isInitialized, user } = useAuth();

  const isAdmin = user?.role === "admin";

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  } else if (!isAdmin) {
    return <Navigate to="/auth/no-permission" />;
  }
  return <React.Fragment>{children}</React.Fragment>;
}

export default AdminGuard;
