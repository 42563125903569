import { combineReducers } from "redux";
import counterReducer from "../slices/counter";
import jwtReducer from "../slices/jwt";
import userReducer from "../slices/user";
import tourReducer from "../slices/tour";
import bankReducer from "../slices/bank";
import invoiceReducer from "../slices/invoice";
import analyticsReducer from "../slices/analytics";
import useranalysisReducer from "../slices/useranalysis";

const rootReducer = combineReducers({
  counter: counterReducer,
  jwt: jwtReducer,
  user: userReducer,
  tour: tourReducer,
  bank: bankReducer,
  invoice: invoiceReducer,
  analytics: analyticsReducer,
  useranalytics: useranalysisReducer,
});

export default rootReducer;
