import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";

const initialState = {
  users: [],
  count: 0,
  status: null,
  submitted: false,
  userbyyear: [],
};

// A mock function to mimic making an async request for data
// export function fetchUser(amount = 1) {
//   return new Promise((resolve) =>
//     setTimeout(() => resolve({ data: amount }), 500)
//   );
// }

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// /auth/users?page=1&perPage=6
export const fetchUsers = createAsyncThunk("user/users", async () => {
  const response = await http.get("/auth/users");

  //console.log(response.data);
  return response.data;
});
export const fetchUpdatedUsers = createAsyncThunk(
  "user/updatedUser",
  async () => {
    const response = await http.get("/auth/users");

    //console.log(response.data);
    return response.data;
  }
);
export const fetchUsersbyYear = createAsyncThunk(
  "user/usersbyyear",
  async () => {
    const response = await http.get("/auth/count");

    //console.log(response.data);
    return response.data;
  }
);
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ id, values }) => {
    //console.log(id);
    const response = await http("/auth/edit/" + id, {
      method: "Put",
      data: values,
      headers: { "Content-Type": "application/json" },
    });
    // setisActive(!isactive);
    //console.log(response);
    //console.log(response.data);
    return response.data;
  }
);
export const EditUser = createAsyncThunk(
  "user/EditUser",
  async ({ values }) => {
    const response = await http("/auth/edit/", {
      method: "Put",
      data: values,
      headers: { "Content-Type": "application/json" },
    });
    // setisActive(!isactive);
    //console.log(response);
    //console.log(response.data);
    return response.data;
  }
);
export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ id }) => {
    //console.log(id);
    const response = await http.delete("/auth/delete/" + id);
    // setisActive(!isactive);
    //console.log(response);
    //console.log(response.data);
    return response.data;
  }
);

export const fetchUsersByDetails = createAsyncThunk(
  "user/users/ByDetails",
  async ({ name, email }) => {
    const response = await http.post("/auth/users/byDetails", {
      nameChar: name,
      emailChar: email,
    });

    //console.log("details", response);

    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // statusChange: (state, action) => {
    //   //console.log({ action });
    //   state.users[action.payload] =
    //     state.users[action.payload] === "active" ? "inactive" : "active";
    // },
  },
  extraReducers: (builder) => {
    builder
      // fetch all users
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        const { users } = action.payload;
        const { count } = action.payload;
        state.status = "idle";
        state.count = count;
        state.users = users;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = null;
        state.users = [];
        state.count = 0;
        //console.log(action);
      })

      .addCase(fetchUpdatedUsers.fulfilled, (state, action) => {
        const { users } = action.payload;
        const { count } = action.payload;
        // state.status = "idle";
        state.count = count;
        state.users = users;
      })
      // .addCase(deleteUser.pending, (state) => {
      //   // state.status = "loading";
      // })
      // .addCase(deleteUser.fulfilled, (state, action) => {
      //   //console.log(action);
      //   // const { users } = action.payload;
      //   // const { count } = action.payload;
      //   // state.status = "idle";
      //   // state.count = count;
      //   // state.users = users;
      // })
      // .addCase(deleteUser.rejected, (state, action) => {
      //   // state.status = null;
      //   // state.users = [];
      //   // state.count = 0;
      //   //console.log(action);
      // })
      // .addCase(updateUser.pending, (state, action) => {
      //   // state.status = "loading";
      // })
      .addCase(updateUser.fulfilled, (state, action) => {
        const { user } = action.payload;
        //console.log(user);
        // state.status = "idle";
        state.users = state.users.map((item) =>
          item._id === user._id ? user : item
        );

        // state.users[user] = user;
        // reInitialize();
      })
      .addCase(updateUser.rejected, (state, action) => {
        //console.log(action);
        // state.status = null;
        state.users = [];
        state.count = 0;
        //console.log(action);
      })

      .addCase(EditUser.fulfilled, (state, action) => {
        const { user } = action.payload;
        //console.log(user);
        // state.status = "idle";
        state.users = state.users.map((item) =>
          item._id === user._id ? user : item
        );
        state.submitted = true;
        // state.users[user] = user;
      })
      .addCase(EditUser.rejected, (state, action) => {
        //console.log(action);
        // state.status = null;
        state.users = [];
        state.count = 0;
        //console.log(action);
      })
      .addCase(fetchUsersbyYear.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersbyYear.fulfilled, (state, action) => {
        const { count } = action.payload;
        state.status = "idle";
        state.userbyyear = count;
      })
      .addCase(fetchUsersbyYear.rejected, (state, action) => {
        state.status = null;
        state.userbyyear = [];
        //console.log(action);
      })
      // fetch all users by details
      .addCase(fetchUsersByDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersByDetails.fulfilled, (state, action) => {
        const { users } = action.payload;
        const { count } = action.payload;
        state.status = "idle";
        state.users = users;
        state.count = count;
      })
      .addCase(fetchUsersByDetails.rejected, (state, action) => {
        state.status = null;
        state.users = [];
        //console.log(action);
      });
  },
});

// export const {} = jwtSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state) => state.user;
// export const { statusChange } = userSlice.actions;
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default userSlice.reducer;
