import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
// import {
//   Add as AddIcon,
//   Archive as ArchiveIcon,
//   FilterList as FilterListIcon,
//   RemoveRedEye as RemoveRedEyeIcon,
// } from "@mui/icons-material";
import { spacing } from "@mui/system";
import useAppDispatch from "../../hooks/useAppDispatch";
// import { fetchInvoices } from "../../redux/slices/invoice";
// import { useSelector } from "react-redux";
import http from "../../utils/http";
import { useSelector } from "react-redux";
import { fetchInvoices } from "../../redux/slices/invoice";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};
  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

// const Avatar = styled(MuiAvatar)`
//   background: ${(props) => props.theme.palette.primary.main};
// `;

// const Customer = styled.div`
//   display: flex;
//   align-items: center;
// `;

// function createData(id, date, price, invoiceFor, status) {
//   return { id, date, price, invoiceFor, status };
// }
// function createData(
//   customer,
//   customerEmail,
//   customerAvatar,
//   status,
//   amount,
//   id,
//   date
// ) {
//   return { customer, customerEmail, customerAvatar, status, amount, id, date };
// }

// const rows = [
//   createData("002", "2021-01-03", 5, 30, "awaiting"),
//   createData("001", "2021-01-02", 2, 7, "paid"),
//   createData("005", "2021-02-02", 3, 365, "paid"),
//   createData("003", "2021-01-02", 1, 1, "cancelled"),
// ];

//console.log("ROWWWS", rows);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

// const headCells = [
//   { id: "customer", alignment: "left", label: "Customer" },
//   { id: "status", alignment: "left", label: "Status" },
//   { id: "id", alignment: "right", label: "ID" },
//   { id: "amount", alignment: "right", label: "Amount" },
//   { id: "date", alignment: "left", label: "Issue Date" },
//   { id: "actions", alignment: "right", label: "Actions" },
// ];

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "date", alignment: "left", label: "Issue Date" },
  { id: "price", alignment: "left", label: "Price" },
  { id: "invoiceFor", alignment: "left", label: "Type" },
  { id: "status", alignment: "left", label: "Status" },
  { id: "actions", alignment: "left", label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Invoices
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      {/* <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div> */}
    </Toolbar>
  );
};

function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { invoices } = useSelector((state) => state.invoice);
  const appDispatch = useAppDispatch();
  const [rows, setRows] = useState([]);
  // const [invoices, setInvoices] = useState([]);
  //console.log(
  //   "HTTP AUTH AT InvoiceList.js",
  //   http.defaults.headers.common.Authorization
  // );

  useEffect(() => {
    const fetchInvoice = async () => {
      // const response = await http.get("/invoice");
      // const invoices = response.data.invoices;
      // setInvoices(invoices);
      appDispatch(fetchInvoices());
      const tempRows = invoices.map((invoice) => {
        let totalAmount = 0;
        invoice.items.forEach((item) => (totalAmount += Number(item.amount)));
        return {
          id: invoice.count,
          date: new Date(invoice.date).toDateString(),
          price: totalAmount,
          invoiceFor: invoice.invoiceFor,
          status: invoice.status,
          _id: invoice._id,
          invoiceUrl: invoice.invoiceUrl,
          totalprice: invoice.total,
        };
      });
      setRows(tempRows);
    };
    fetchInvoice();
  }, [appDispatch]);

  //console.log("invoices-list", invoices);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, id) => {
  //   const selectedIndex = selected.indexOf(id);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const returnInvoiceForInWords = (invoiceFor) => {
    if (invoiceFor === 1) {
      return "One Time";
    } else if (invoiceFor === 7) {
      return "Weekly";
    } else if (invoiceFor === 30) {
      return "Monthly";
    } else if (invoiceFor === 365) {
      return "Yearly";
    }
  };

  const paidHandler = async (e) => {
    try {
      await http.put(`/invoice/paid/${e.target.dataset.invoiceid}`);
      setRows((prevRows) => {
        //console.log("Prev Rows", prevRows);
        const newRows = prevRows.map((row) => {
          if (row._id === e.target.dataset.invoiceid) {
            row.status = "awaiting";
          }
          return row;
        });
        return newRows;
      });
    } catch (err) {
      console.error(err);
    }
  };

  const returnStatusWithColor = (status) => {
    switch (status) {
      case "awaiting":
        return (
          <Chip
            size="small"
            sx={{
              backgroundColor: "yellow",
              color: "black",
              fontWeight: "600",
            }}
            label="awaiting"
          ></Chip>
        );
      case "notPaid":
        return (
          <Chip
            size="small"
            sx={{
              backgroundColor: "orange",
              color: "black",
              fontWeight: "600",
            }}
            label="Not Paid"
          ></Chip>
        );
      case "paid":
        return (
          <Chip
            size="small"
            sx={{ backgroundColor: "green", color: "white", fontWeight: "600" }}
            label="Paid"
          ></Chip>
        );
      case "cancelled":
        return (
          <Chip
            size="small"
            sx={{ backgroundColor: "red", color: "black", fontWeight: "600" }}
            label="cancelled"
          ></Chip>
        );
      default:
        return "unknown";
    }
  };

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {/* {//console.log(rows)} */}
            <TableBody>
              {rows.length > 0 ? (
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    // const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell> */}
                        <TableCell align="left">#{row.id}</TableCell>
                        <TableCell>{row.date}</TableCell>
                        <TableCell>${row.totalprice.toFixed(2)}</TableCell>
                        <TableCell align="left">
                          {returnInvoiceForInWords(row.invoiceFor)}
                        </TableCell>
                        <TableCell>
                          {/* {row.status === 0 && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Sent"
                            sent={+true}
                          />
                        )}
                        {<Chip size="small" mr={1} mb={1} label={row.status} />} */}
                          {returnStatusWithColor(row.status)}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={paidHandler}
                            data-invoiceid={row._id}
                            disabled={row.status !== "notPaid"}
                            size="small"
                            sx={{
                              marginRight: "8px",
                              backgroundColor: "green",
                              "&:hover": { backgroundColor: "#006600" },
                            }}
                          >
                            I Have Paid
                          </Button>
                          <Button variant="contained" size="small">
                            <Link
                              href={row.invoiceUrl}
                              sx={{
                                color: "white",
                                "&:hover": { textDecoration: "none" },
                              }}
                            >
                              Download Invoice
                            </Link>
                          </Button>
                        </TableCell>
                        {/* <TableCell align="right">
                        <IconButton aria-label="delete" size="large">
                          <ArchiveIcon />
                        </IconButton>
                        <IconButton
                          aria-label="details"
                          component={NavLink}
                          to="/invoices/detail"
                          size="large"
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </TableCell> */}
                      </TableRow>
                    );
                  })
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ width: "100%" }}
                  margin="10px"
                >
                  <Grid item xs>
                    <Typography
                      variant="h5"
                      align="right"
                      fluid
                      width={"full"}
                      noWrap
                    >
                      No Invoice Available
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function InvoiceList() {
  return (
    <React.Fragment>
      <Helmet title="Invoices" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Invoices
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Invoices</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <AddIcon />
              New Invoice
            </Button>
          </div>
        </Grid> */}
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InvoiceList;
