import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";

const initialState = {
  tours: [],
  adminTours: [],
  tour: {},
  adminTour: {},
  currentUserTours: [],
  error: null,
  status: null,
};

// A mock function to mimic making an async request for data
// export function fetchUser(amount = 1) {
//   return new Promise((resolve) =>
//     setTimeout(() => resolve({ data: amount }), 500)
//   );
// }

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchUserTours = createAsyncThunk("tour/userTours", async (id) => {
  const response = await http.get(`/tours/user/${id}`);
  //console.log("fetchAllTours", response);
  return response.data;
});

export const fetchTours = createAsyncThunk("tour/tours", async () => {
  const response = await http.get("/tours");
  //console.log("fetchTours", response);
  return response.data;
});
export const fetchUpdatedTours = createAsyncThunk(
  "tour/updatedtours",
  async () => {
    const response = await http.get("/tours");
    //console.log("fetchTours", response);
    return response.data;
  }
);

export const fetchAllTours = createAsyncThunk("tour/allTours", async () => {
  const response = await http.get("/tours/all");
  //console.log("fetchAllTours", response);
  return response.data;
});
export const fetchUpdatedAllTours = createAsyncThunk(
  "tour/updatedallTours",
  async () => {
    const response = await http.get("/tours/all");
    //console.log("fetchAllTours", response);
    return response.data;
  }
);
export const fetchAllAdminTours = createAsyncThunk(
  "tour/getAdminTours",
  async () => {
    const response = await http.get("/tours/getAdminTours");
    //console.log("fetchAllTours", response);
    return response.data;
  }
);

export const getTour = createAsyncThunk("tour/getTour", async (tourId) => {
  //console.log({ tourId, localStorage });
  const response = await http.get("/tours/" + tourId);
  //console.log("getTour", response);
  return response.data;
});

export const getAdminTour = createAsyncThunk("tour/getAdminTour", async (tourId) => {
  //console.log({ tourId, localStorage });
  const response = await http.get("/tours/adminTour/" + tourId);
  //console.log("getTour", response);
  return response.data;
});
export const saveTour = createAsyncThunk("tour/saveTour", async (body) => {
  const response = await http.post("/tours", body);
  //console.log("saveTour", response);
  return response.data;
});

export const deleteTour = createAsyncThunk(
  "tour/deleteTour",
  async (tourId) => {
    //console.log(tourId);
    const response = await http.delete("/tours/" + tourId);
    //console.log("deleteTour", response);
    return response.data;
  }
);

export const editTour = createAsyncThunk("tour/editTour", async (tour) => {
  //console.log(tour);
  const response = await http.put("/tours/edit/" + tour._id, tour);
  if (response.status === 500) {
    throw new Error("Server side error");
  }
  //console.log("editTour", response);
  return response.data;
});

export const tourSlice = createSlice({
  name: "tour",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // signUp: (state, action) => {
    //   state.isAuthenticated = true;
    //   state.tour = action.payload.tour;
    // },
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // fetch all tours starts
      .addCase(fetchAllTours.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchAllTours.fulfilled, (state, action) => {
        const { tours } = action.payload;
        state.status = "idle";
        state.tours = tours;
      })
      .addCase(fetchAllTours.rejected, (state, action) => {
        state.status = null;
        state.tours = [];
        state.error = action.payload;
      })
      //fetch updated tours
      .addCase(fetchUpdatedAllTours.fulfilled, (state, action) => {
        const { tours } = action.payload;
        // state.status = "idle";
        state.tours = tours;
      })
      //fetch admin tours
      .addCase(fetchAllAdminTours.fulfilled, (state, action) => {
        const { tours } = action.payload;
        // state.status = "idle";
        state.adminTours = tours;
      })
      // get a tour
      .addCase(getTour.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getTour.fulfilled, (state, action) => {
        const { tour } = action.payload;
        state.status = "idle";
        state.tour = tour;
      })
      .addCase(getTour.rejected, (state, action) => {
        state.status = null;
        state.tour = [];
        state.error = action.payload;
      })
      .addCase(getAdminTour.fulfilled, (state, action) => {
        const { tour } = action.payload;
        state.status = "idle";
        state.adminTour = tour;
      })
      // fetch tours starts
      .addCase(fetchTours.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchTours.fulfilled, (state, action) => {
        const { tours } = action.payload;
        state.status = "idle";
        state.tours = tours;
      })
      .addCase(fetchTours.rejected, (state, action) => {
        state.status = null;
        state.tours = [];
        state.error = action.payload;
      })
      // usertours
      .addCase(fetchUserTours.fulfilled, (state, action) => {
        const { tours } = action.payload;
        state.currentUserTours = tours;
      })
      // save Tours
      .addCase(saveTour.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(saveTour.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(saveTour.rejected, (state, action) => {
        state.status = null;
        state.error = action.payload;
      })
      // edit Tour
      .addCase(editTour.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(editTour.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(editTour.rejected, (state, action) => {
        state.status = null;
        state.error = action.payload;
      })
      // delete Tour
      .addCase(deleteTour.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteTour.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(deleteTour.rejected, (state, action) => {
        state.status = null;
        state.error = action.payload;
      });
  },
});

// export const {} = jwtSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTour = (state) => state.tour;
export const selectUserTours = (state) => state?.tour?.currentUserTours || [];

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default tourSlice.reducer;
