import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// import DocLayout from "./layouts/Doc";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignOut from "./pages/auth/SignOut";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import ResetPasswordNew from "./pages/auth/ResetPasswordNew";
import Page404 from "./pages/auth/Page404";
import NotPermittedPage from "./pages/auth/NotPermitted";
import Page500 from "./pages/auth/Page500";

// Components
// import Accordion from "./pages/components/Accordion";
// import Alerts from "./pages/components/Alerts";
// import Avatars from "./pages/components/Avatars";
// import Badges from "./pages/components/Badges";
// import Buttons from "./pages/components/Buttons";
// import Cards from "./pages/components/Cards";
// import Chips from "./pages/components/Chips";
// import Dialogs from "./pages/components/Dialogs";
// import Lists from "./pages/components/Lists";
// import Menus from "./pages/components/Menus";
// import Pagination from "./pages/components/Pagination";
// import Progress from "./pages/components/Progress";
// import Snackbars from "./pages/components/Snackbars";
// import Tooltips from "./pages/components/Tooltips";

// Form components
// import SelectionCtrls from "./pages/forms/SelectionControls";
// import Selects from "./pages/forms/Selects";
// import TextFields from "./pages/forms/TextFields";

// Icon components
// import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
// import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
// import Orders from "./pages/pages/Orders";
// import Pricing from "./pages/pages/Pricing";
// import Settings from "./pages/pages/Settings";
// import Projects from "./pages/pages/Projects";
// import Chat from "./pages/pages/Chat";

// Table components
// import SimpleTable from "./pages/tables/SimpleTable";
// import AdvancedTable from "./pages/tables/AdvancedTable";

// Documentation
// import Welcome from "./pages/docs/Welcome";
// import GettingStarted from "./pages/docs/GettingStarted";
// import Routing from "./pages/docs/Routing";
// import Auth0 from "./pages/docs/auth/Auth0";
// import Cognito from "./pages/docs/auth/Cognito";
// import Firebase from "./pages/docs/auth/Firebase";
// import JWT from "./pages/docs/auth/JWT";
// import Guards from "./pages/docs/Guards";
// import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
// import Deployment from "./pages/docs/Deployment";
// import Theming from "./pages/docs/Theming";
// import APICalls from "./pages/docs/APICalls";
// import Redux from "./pages/docs/Redux";
// import Internationalization from "./pages/docs/Internationalization";
// import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
// import Support from "./pages/docs/Support";
// import Changelog from "./pages/docs/Changelog";

// Landing
// import Landing from "./pages/presentation/Landing";

// Protected routes
// import ProtectedPage from "./pages/protected/ProtectedPage";
import { Navigate } from "react-router-dom";
import EditProfile from "./pages/pages/EditProfile";
import AdminEditProfile from "./pages/admin/EditAdminOwnProfile";
// import UserProfile from "./pages/pages/UserProfile";
import AdminUserProfile from "./pages/admin/UserProfile";
import EditUser from "./pages/admin/ManageUsers/EditUser";
// import Dashboard from "./pages/pages/Dashboard";
import AdminDashboard from "./pages/admin/Dashboard";
import EditInvoice from "./pages/admin/ManageInvoices/EditInvoice";
import EditBank from "./pages/admin/ManageBanks/EditBank";
import EditVT from "./pages/admin/ManageVT/EditVT";
// import AdvancedTable from "./pages/tables/AdvancedTable";
// import UserVirtualList from "./components/userDashboard/Tours/ManageToursUser/viewUserTours";
import Tours from "./components/userDashboard/Tours/index";
import AdminGuard from "./components/guards/AdminGuard";
import OurWorks from "./pages/admin/ManageVT/OurWorks";
import CreateOurWork from "./pages/admin/ManageVT/CreateOurWork";
import EditAdminVT from "./pages/admin/ManageVT/EditAdminVT";
// import InvoiceDetails from "./pages/admin/InvoiceDetails";

// Dashboard components
// const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const MyTourAnalysis = async(() =>
  import("./components/userDashboard/Tours/Analytics/index")
);
// const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
// const Pickers = async(() => import("./pages/forms/Pickers"));
// const Editors = async(() => import("./pages/forms/Editors"));
// const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
// const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const AdminProfile = async(() => import("./pages/admin/Profile"));
const AllUsers = async(() => import("./pages/admin/ManageUsers/AllUsers"));
const AdminSignUp = async(() =>
  import("./pages/admin/ManageUsers/AdminSignUp")
);
const UploadVT = async(() => import("./pages/admin/ManageVT/UploadVT"));
const VTList = async(() => import("./pages/admin/ManageVT/VTList"));
const CreateInvoice = async(() =>
  import("./pages/admin/ManageInvoices/CreateInvoice")
);
const AddNewInvoice = async(() =>
  import("./pages/admin/ManageInvoices/AddNewInvoice")
);
const ManageBanks = async(() =>
  import("./pages/admin/ManageBanks/ManageBanks")
);
const AddNewBank = async(() => import("./pages/admin/ManageBanks/AddNewBank"));
// const Tasks = async(() => import("./pages/pages/Tasks"));
// const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
// const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
// const Chartjs = async(() => import("./pages/charts/Chartjs"));
// const ApexCharts = async(() => import("./pages/charts/ApexCharts"));

// Maps components
// const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element: <Navigate to="auth/sign-in" replace />,
  },

  {
    path: "pages",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      // {
      //   path: "dashboard",
      //   element: <Dashboard />,
      // },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "edit",
        element: <EditProfile />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },

  {
    path: "admin",
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "profile",
        element: <AdminProfile />,
      },
      {
        path: "profile/:id",
        element: <AdminUserProfile />,
      },
      {
        path: "user/:id",
        element: <EditUser />,
      },
      {
        path: "edit",
        element: <AdminEditProfile />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
      {
        path: "sign-up",
        element: <AdminSignUp />,
      },
      {
        path: "upload-tours",
        element: <UploadVT />,
      },
      {
        path: "create-our-work",
        element: <CreateOurWork />,
      },
      {
        path: "our-works",
        element: <OurWorks />,
      },
      {
        path: "virtual-tours",
        element: <VTList />,
      },
      {
        path: "all-users",
        element: <AllUsers />,
      },
      {
        path: "create-user",
        element: <AdminSignUp />,
      },
      {
        path: "manage-banks",
        element: <ManageBanks />,
      },
      {
        path: "add-new-bank",
        element: <AddNewBank />,
      },
      {
        path: "invoices",
        element: <CreateInvoice />,
        children: [
          {
            path: ":id",
            element: <InvoiceDetails />,
          },
        ],
      },
      {
        path: "create-new-invoice",
        element: <AddNewInvoice />,
      },
      {
        path: "invoice",
        children: [
          {
            path: "edit/:id",
            element: <EditInvoice />,
          },
        ],
      },
      {
        path: "bank",
        children: [
          {
            path: "edit/:id",
            element: <EditBank />,
          },
        ],
      },
      {
        path: "vt",
        children: [
          {
            path: "edit/:id",
            element: <EditVT />,
          },
        ],
      },
      {
        path: "admin-vt",
        children: [
          {
            path: "edit/:id",
            element: <EditAdminVT />,
          },
        ],
      },
      {
        path: "user",
        children: [
          {
            path: "edit/:id",
            element: <EditUser />,
          },
          {
            path: "detail",
            element: <InvoiceDetails />,
          },
        ],
      },
    ],
  },

  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-out",
        element: <SignOut />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "reset-password-new",
        element: <ResetPasswordNew />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "no-permission",
        element: <NotPermittedPage />,
      },
    ],
  },

  // {
  //   path: "dashboard",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "default",
  //       element: <Default />,
  //     },
  //     {
  //       path: "analytics",
  //       element: <Analytics />,
  //     },
  //     {
  //       path: "saas",
  //       element: <SaaS />,
  //     },
  //   ],
  // },
  // {
  //   path: "projects",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Projects />,
  //     },
  //   ],
  // },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: ":id",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "virtual-tours",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tours />,
      },
      {
        path: "my-tour",
        element: <MyTourAnalysis />,
      },
    ],
  },
  // {
  //   path: "orders",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Orders />,
  //     },
  //   ],
  // },
  // {
  //   path: "tasks",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Tasks />,
  //     },
  //   ],
  // },
  // {
  //   path: "calendar",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Calendar />,
  //     },
  //   ],
  // },

  // {
  //   path: "components",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "accordion",
  //       element: <Accordion />,
  //     },
  //     {
  //       path: "alerts",
  //       element: <Alerts />,
  //     },
  //     {
  //       path: "avatars",
  //       element: <Avatars />,
  //     },
  //     {
  //       path: "badges",
  //       element: <Badges />,
  //     },
  //     {
  //       path: "buttons",
  //       element: <Buttons />,
  //     },
  //     {
  //       path: "cards",
  //       element: <Cards />,
  //     },
  //     {
  //       path: "chips",
  //       element: <Chips />,
  //     },
  //     {
  //       path: "dialogs",
  //       element: <Dialogs />,
  //     },
  //     {
  //       path: "lists",
  //       element: <Lists />,
  //     },
  //     {
  //       path: "menus",
  //       element: <Menus />,
  //     },
  //     {
  //       path: "pagination",
  //       element: <Pagination />,
  //     },
  //     {
  //       path: "progress",
  //       element: <Progress />,
  //     },
  //     {
  //       path: "snackbars",
  //       element: <Snackbars />,
  //     },
  //     {
  //       path: "tooltips",
  //       element: <Tooltips />,
  //     },
  //   ],
  // },
  // {
  //   path: "forms",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "pickers",
  //       element: <Pickers />,
  //     },
  //     {
  //       path: "selection-controls",
  //       element: <SelectionCtrls />,
  //     },
  //     {
  //       path: "selects",
  //       element: <Selects />,
  //     },
  //     {
  //       path: "text-fields",
  //       element: <TextFields />,
  //     },
  //     {
  //       path: "editors",
  //       element: <Editors />,
  //     },
  //     {
  //       path: "formik",
  //       element: <Formik />,
  //     },
  //   ],
  // },
  // {
  //   path: "tables",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "simple-table",
  //       element: <SimpleTable />,
  //     },
  //     {
  //       path: "advanced-table",
  //       element: <AdvancedTable />,
  //     },
  //     {
  //       path: "data-grid",
  //       element: <DataGrid />,
  //     },
  //   ],
  // },
  // {
  //   path: "icons",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "material-icons",
  //       element: <MaterialIcons />,
  //     },
  //     {
  //       path: "feather-icons",
  //       element: <FeatherIcons />,
  //     },
  //   ],
  // },
  // {
  //   path: "charts",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "chartjs",
  //       element: <Chartjs />,
  //     },
  //     {
  //       path: "apexcharts",
  //       element: <ApexCharts />,
  //     },
  //   ],
  // },
  // {
  //   path: "maps",
  //   element: <DashboardLayout />,
  //   children: [
  //     {
  //       path: "google-maps",
  //       element: <GoogleMaps />,
  //     },
  //     {
  //       path: "vector-maps",
  //       element: <VectorMaps />,
  //     },
  //   ],
  // },
  // {
  //   path: "documentation",
  //   element: <DocLayout />,
  //   children: [
  //     {
  //       path: "welcome",
  //       element: <Welcome />,
  //     },
  //     {
  //       path: "getting-started",
  //       element: <GettingStarted />,
  //     },
  //     {
  //       path: "routing",
  //       element: <Routing />,
  //     },
  //     {
  //       path: "auth/auth0",
  //       element: <Auth0 />,
  //     },
  //     {
  //       path: "auth/cognito",
  //       element: <Cognito />,
  //     },
  //     {
  //       path: "auth/firebase",
  //       element: <Firebase />,
  //     },
  //     {
  //       path: "auth/jwt",
  //       element: <JWT />,
  //     },
  //     {
  //       path: "guards",
  //       element: <Guards />,
  //     },
  //     {
  //       path: "environment-variables",
  //       element: <EnvironmentVariables />,
  //     },
  //     {
  //       path: "deployment",
  //       element: <Deployment />,
  //     },
  //     {
  //       path: "theming",
  //       element: <Theming />,
  //     },
  //     {
  //       path: "api-calls",
  //       element: <APICalls />,
  //     },
  //     {
  //       path: "redux",
  //       element: <Redux />,
  //     },
  //     {
  //       path: "internationalization",
  //       element: <Internationalization />,
  //     },
  //     {
  //       path: "eslint-and-prettier",
  //       element: <ESLintAndPrettier />,
  //     },
  //     {
  //       path: "support",
  //       element: <Support />,
  //     },
  //   ],
  // },
  // {
  //   path: "changelog",
  //   element: <DocLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Changelog />,
  //     },
  //   ],
  // },
  // {
  //   path: "private",
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <ProtectedPage />,
  //     },
  //   ],
  // },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
