import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";

const initialState = {
  loading: true,
  data: [],
  button: "Last 30 days",
  error: "",
};

export const getAnalytics = createAsyncThunk(
  "google/getAnalytics",
  async (value = "30daysAgo") => {
    const response = await http.get(`/analytics?value=${value}`);
    return response.data;
  }
);

export const analyticsSlice = createSlice({
  name: "GoogleAnalytics",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    buttonAdmin: (state, param) => {
      const { payload } = param;
      state.button = payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get data
      .addCase(getAnalytics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.data = action.payload;
      })
      .addCase(getAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      });
  },
});

const { actions, reducer } = analyticsSlice;
export const { buttonAdmin } = actions;
export default reducer;
