import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Box,
  CircularProgress,
  Link,
  // Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, display } from "@mui/system";
import { useSelector } from "react-redux";
import { getInvoice, selectInvoiceById } from "../../redux/slices/invoice";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAuth from "../../hooks/useAuth";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

// const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function InvoiceDetails() {
  const { id } = useParams();
  const appDispatch = useAppDispatch();
  useEffect(() => {
    appDispatch(getInvoice(id));
  }, [id]);
  const invoice = useSelector(selectInvoiceById);
  //console.log({ invoice });
  let { user } = useAuth();
  return invoice && invoice["to"] ? (
    <React.Fragment>
      <Helmet title="Invoice Details" />

      <Typography variant="h3" gutterBottom display="inline">
        Invoice
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Link component={NavLink} to="/">
          Invoices
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    {user && user?.role === "admin" ? (
                      <Typography variant="body2" gutterBottom>
                        Hello Admin,
                        <br />
                        This is the receipt for a payment of $
                        {invoice["total"].toFixed(2)} (USD) you made to{" "}
                        {invoice["to"][0]["toName"]}.
                      </Typography>
                    ) : user && user?.role === "user" ? (
                      <Typography variant="body2" gutterBottom>
                        Hello {invoice["to"][0]["toName"]},
                        <br />
                        This is the receipt for a payment of $
                        {invoice["total"].toFixed(2)} (USD) you made to{" "}
                        {invoice["fromName"]}.
                      </Typography>
                    ) : (
                      <Typography variant="body2" gutterBottom>
                        No User
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Payment No.</Typography>
                    <Typography variant="body2">#{invoice.count}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Payment Date
                    </Typography>
                    <Typography variant="body2" align="right">
                      {new Date(invoice["date"]).toUTCString().slice(0, 17)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Client</Typography>
                    <Typography variant="body2">
                      {invoice["to"][0]["toName"]}
                      <br />
                      {invoice["fromAddress"]}
                      <br />
                      <Link href={`mailto:${invoice["to"][0]["toEmail"]}`}>
                        {invoice["to"][0]["toEmail"]}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Payment To
                    </Typography>
                    <Typography variant="body2" align="right">
                      {invoice["fromName"]}
                      <br />
                      <Link href={`mailto:${invoice["fromEmail"]}`}>
                        {invoice["fromEmail"]}
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card px={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice["items"].map((item) => {
                    return (
                      <TableRow key={item["_id"]}>
                        <TableCell component="th" scope="row">
                          {item["desc"]}
                        </TableCell>
                        <TableCell>{item["quantity"]}</TableCell>
                        <TableCell>${item["rate"]}</TableCell>
                        <TableCell align="right">${item["amount"]}</TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell />
                    <TableCell></TableCell>
                    <TableCell>Subtotal</TableCell>
                    <TableCell align="right">${invoice["subtotal"]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell></TableCell>
                    <TableCell>tax</TableCell>
                    <TableCell align="right">{invoice["tax"]}%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell></TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">
                      ${invoice["total"].toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            <Card pb={6} px={6}>
              <CardContent style={{ textAlign: "center" }}>
                <div>
                  <Typography variant="caption" gutterBottom align="center">
                    Extra note:Thank you for choosing our services.
                  </Typography>
                </div>
                <a
                  style={{
                    color: "white",
                    borderRadius: "5px",
                    backgroundColor: "#5858d5",
                    textDecoration: "none",
                    height: "13px",
                    width: "35px",
                    padding: "3px",
                    fontSize: "15px",
                  }}
                  href={invoice["invoiceUrl"]}
                  rel="noopener noreferrer"
                  variant="contained"
                  color="primary"
                  mt={2}
                >
                  Print this receipt
                </a>
              </CardContent>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </React.Fragment>
  ) : (
    <Box display="flex" alignItems="center" justifyContent="center" my={6}>
      <CircularProgress />
    </Box>
  );
}

export default InvoiceDetails;
