import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";

const initialState = {
  invoices: [],
  invoice: {},
  status: null,
  invoiceCount: [],
  invoiceCountnopaid: [],
  currentUsersInvoices: [],
  paid: 0,
  notpaid: 0,
};

export const fetchInvoicesByUserId = createAsyncThunk(
  "invoice/userInvoices",
  async (id) => {
    //console.log("CHECKING SETTED");
    const response = await http.get(`/invoice/user/${id}`);
    return response.data;
  }
);

export const fetchInvoices = createAsyncThunk(
  "invoice/allInvoices",
  async () => {
    //console.log("CHECKING SETTED", http.defaults.headers.common.Authorization);
    const response = await http.get("/invoice");

    //console.log(response);
    return response.data;
  }
);
export const fetchUpdatedInvoices = createAsyncThunk(
  "invoice/allupdatedInvoices",
  async () => {
    //console.log("CHECKING SETTED", http.defaults.headers.common.Authorization);
    const response = await http.get("/invoice");

    //console.log(response);
    return response.data;
  }
);
export const fetchInvoicesbymonthspaid = createAsyncThunk(
  "invoice/allInvoicesbyyear",
  async () => {
    //console.log("CHECKING SETTED", http.defaults.headers.common.Authorization);
    const response = await http.get("/invoice/count/?type=paid");

    //console.log(response);
    return response.data;
  }
);
export const fetchInvoicesbymonthsnotpaid = createAsyncThunk(
  "invoice/allInvoicesbyyearnopaid",
  async () => {
    //console.log("CHECKING SETTED", http.defaults.headers.common.Authorization);
    const response = await http.get("/invoice/count/?type=notPaid");

    //console.log(response);
    return response.data;
  }
);

export const getInvoice = createAsyncThunk(
  "invoice/getInvoice",
  async (invoiceId) => {
    const response = await http.get("/invoice/" + invoiceId);
    //console.log("RESPONSE", response);
    return response.data;
  }
);

export const saveInvoice = createAsyncThunk(
  "invoice/saveInvoice",
  async (invoice) => {
    //console.log("POSTINGGG", invoice);
    const response = await http.post("/invoice", invoice);

    return response.data;
  }
);

export const editInvoice = createAsyncThunk(
  "invoice/editInvoice",
  async (invoice) => {
    const response = await http.put("/invoice/" + invoice._id, invoice);

    //console.log("editInvoiceResponse", response);
    return response.data;
  }
);

export const deleteInvoice = createAsyncThunk(
  "invoice/deleteInvoice",
  async (invoiceId) => {
    const response = await http.delete("/invoice/" + invoiceId);

    return response.data;
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoicesByUserId.fulfilled, (state, action) => {
        const { invoices } = action.payload;
        state.currentUsersInvoices = invoices;
      })
      // fetch all invoices
      .addCase(fetchInvoices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        //console.log(action);
        const { invoices, paid, notpaid } = action.payload;
        state.status = "idle";
        state.invoices = invoices;
        state.paid = paid;
        state.notpaid = notpaid;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.status = null;
        state.invoices = [];
        //console.log(action);
      })
      .addCase(fetchUpdatedInvoices.fulfilled, (state, action) => {
        //console.log(action);
        const { invoices, paid, notpaid } = action.payload;
        state.invoices = invoices;
        state.paid = paid;
        state.notpaid = notpaid;
      })
      .addCase(fetchInvoicesbymonthspaid.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInvoicesbymonthspaid.fulfilled, (state, action) => {
        const { count } = action.payload;
        state.status = "idle";
        state.invoiceCount = count;
      })
      .addCase(fetchInvoicesbymonthspaid.rejected, (state, action) => {
        state.status = null;
        state.invoiceCount = [];
        //console.log(action);
      })
      .addCase(fetchInvoicesbymonthsnotpaid.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInvoicesbymonthsnotpaid.fulfilled, (state, action) => {
        const { count } = action.payload;
        state.status = "idle";
        state.invoiceCountnopaid = count;
      })
      .addCase(fetchInvoicesbymonthsnotpaid.rejected, (state, action) => {
        state.status = null;
        state.invoiceCountnopaid = [];
        //console.log(action);
      })
      // get invoice by id
      .addCase(getInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getInvoice.fulfilled, (state, action) => {
        const { invoice } = action.payload;
        state.status = "idle";
        state.invoice = invoice;
      })
      .addCase(getInvoice.rejected, (state, action) => {
        state.status = null;
        state.invoice = {};
        //console.log(action);
      })
      // save an invoice
      .addCase(saveInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(saveInvoice.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(saveInvoice.rejected, (state) => {
        state.status = null;
      })
      // edit an invoice
      .addCase(editInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editInvoice.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(editInvoice.rejected, (state) => {
        state.status = null;
      })
      // delete an invoice
      .addCase(deleteInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(deleteInvoice.rejected, (state) => {
        state.status = null;
      });
  },
});

export const selectInvoice = (state) => state.invoice;
export const selectInvoiceById = (state) =>
  state?.invoice?.invoice || undefined;
export const getUserInvoices = (state) => {
  return state?.invoice?.currentUsersInvoices || [];
};

export default invoiceSlice.reducer;
