import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import http from "../../../utils/http";
// import useAuth from "../../hooks/useAuth";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  // email: Yup.string().email().required("Required"),
  phone: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  // image: Yup.mixed().required("Required").label("File"),

  password: Yup.string().min(6, "Must be at least 6 characters").max(255),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
});

function BasicForm() {
  const [user, setUser] = useState({});
  const [isload, setIsload] = useState(false);

  const { id } = useParams();
  const getUser = async () => {
    try {
      const { data } = await http.get("/auth/users/" + id);
      setUser(data.user);
      setIsload(true);
    } catch (error) {
      return error;
    }
  };

  getUser();

  let UserImage = user.image;
  const [imageData, setImageData] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    role: "",
    phone: "",
    address: "",
    companyName: "",
    image: "",
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    const tempInitialValues = { ...initialValues };
    Object.keys(tempInitialValues).forEach((item) => {
      tempInitialValues[item] = user[item];
    });
    setInitialValues(tempInitialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      // return
      //console.log(values);
      // await timeOut(1500);
      if (imageData) {
        try {
          const formData = new FormData();
          formData.append("file", imageData);
          const { data } = await http("/file/upload", {
            method: "POST",
            data: formData,
            // // data: newValues,
            // headers: { "Content-Type": "application/json" },
          });
          values.image = `https://s3.amazonaws.com/image.thiscvr.com/${data.originalname}`;
          // console.log(data);
        } catch (error) {
          //console.log(error);
        }
      } else {
        values.image = UserImage;
      }

      const { data } = await http("/auth/edit/" + id, {
        method: "Put",
        data: values,
        headers: { "Content-Type": "application/json" },
      });
      //console.log("data", data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: data.msg,
      });
      // resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.msg,
      });
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        // setFieldValue,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Edit a User
            </Typography>
            {/* <Typography variant="body2" gutterBottom>
              Formik example with Yup validation
            </Typography> */}

            {status && status.sent && (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="name"
                      label="Full Name"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} my={2}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="role-label">Role</InputLabel>
                      <Select
                        style={{ width: "100%" }}
                        labelId="role-label"
                        id="role"
                        name="role"
                        value={values.role}
                        label="Age"
                        onChange={handleChange}
                      >
                        <MenuItem value="user">User</MenuItem>
                        <MenuItem value="admin">Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  // onBlur={handleBlur}
                  // onChange={handleChange}
                  type="email"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="phone"
                  label="Phone"
                  value={values.phone}
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="address"
                  label="address"
                  value={values.address}
                  error={Boolean(touched.address && errors.address)}
                  fullWidth
                  helperText={touched.address && errors.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="companyName"
                  label="Company Name"
                  value={values.companyName}
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  helperText={touched.companyName && errors.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />

                {/* <TextField
                  name="img"
                  label="Image URL"
                  value={values.img}
                  error={Boolean(touched.img && errors.img)}
                  fullWidth
                  helperText={touched.img && errors.img}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                /> */}

                <TextField
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="confirmPassword"
                  label="Confirm password"
                  value={values.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  autoComplete="off"
                  variant="outlined"
                  my={2}
                />
                <Grid item md={4}>
                  <TextField
                    style={{ display: "none" }}
                    id="image"
                    name="image"
                    error={Boolean(touched.image && errors.image)}
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      setImageData(event.currentTarget.files[0]);
                    }}
                  />

                  <label htmlFor="image">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <CloudUpload mr={2} /> Upload Profile Photo
                    </Button>
                    <Typography variant="caption" display="block" gutterBottom>
                      For best results, use an image at least 128px by 128px in
                      .jpg format
                    </Typography>
                  </label>
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save changes
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function EditUser() {
  return (
    <React.Fragment>
      <Helmet title="Edit User" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit User
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Manage Users
        </Link>
        <Typography>Edit User</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicForm />
    </React.Fragment>
  );
}

export default EditUser;
