import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import http from "../../utils/http";

const initialState = {
  banks: [],
  bank: {},
  error: null,
  status: null,
};

// A mock function to mimic making an async request for data
// export function fetchUser(amount = 1) {
//   return new Promise((resolve) =>
//     setTimeout(() => resolve({ data: amount }), 500)
//   );
// }

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const fetchBanks = createAsyncThunk("bank/banks", async () => {
  const response = await http.get("/banks");
  return response.data;
});
export const fetchUpdatedBanks = createAsyncThunk(
  "bank/updatedbanks",
  async () => {
    const response = await http.get("/banks");
    return response.data;
  }
);

export const getBank = createAsyncThunk("bank/getBank", async (bankId) => {
  //console.log("edit bank bankId", bankId);
  const response = await http.get("/banks/" + bankId);
  //console.log("get bank response", response);
  return response.data;
});

export const addBank = createAsyncThunk("bank/addBank", async (body) => {
  const response = await http.post("/banks", body);
  if (response.status !== 200) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: response.data.message,
    });
  } else {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: response.data.message,
    });
  }
});

export const editBank = createAsyncThunk("bank/editBank", async (bank) => {
  const response = await http.put("/banks/edit/" + bank._id, bank);
  if (response.status !== 200) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: response.data.message,
    });
  } else {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: response.data.message,
    });
  }
  return response.data;
});

export const deleteBank = createAsyncThunk(
  "bank/deleteBank",
  async (bankId) => {
    const response = await http.delete("/banks/" + bankId);
    return response.data;
  }
);

export const bankSlice = createSlice({
  name: "bank",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // signUp: (state, action) => {
    //   state.isAuthenticated = true;
    //   state.bank = action.payload.bank;
    // },
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get all banks
      .addCase(fetchBanks.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchBanks.fulfilled, (state, action) => {
        const { result } = action.payload;
        state.status = "idle";
        state.banks = result;
      })
      .addCase(fetchBanks.rejected, (state, action) => {
        state.status = null;
        state.banks = [];
        state.error = action.payload;
      })
      .addCase(fetchUpdatedBanks.fulfilled, (state, action) => {
        const { result } = action.payload;
        state.banks = result;
      })
      // get bank by id
      .addCase(getBank.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getBank.fulfilled, (state, action) => {
        const { bank } = action.payload;
        state.status = "idle";
        state.bank = bank;
      })
      .addCase(getBank.rejected, (state, action) => {
        state.status = null;
        state.bank = {};
        state.error = action.payload;
      })
      // add bank
      .addCase(addBank.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addBank.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(addBank.rejected, (state, action) => {
        state.status = null;
        state.error = action.payload;
      })
      // edit bank
      .addCase(editBank.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(editBank.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(editBank.rejected, (state, action) => {
        state.status = null;
        state.error = action.payload;
      })
      // delete bank
      .addCase(deleteBank.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteBank.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(deleteBank.rejected, (state, action) => {
        state.status = null;
        state.error = action.payload;
      });
  },
});

// export const {} = jwtSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBank = (state) => state.bank;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default bankSlice.reducer;
