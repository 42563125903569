import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";
import { Link, Link as LinkRRD } from "react-router-dom";
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const DashboardTable = (props) => {
  //console.log(props);
  const [invoiceData, setInvoiceData] = useState([]);

  useEffect(() => {
    setInvoiceData(props.invoices);
    // if (props.invoices.length > 5) {
    //   for (let i = 0; i < 5; i++) {
    //     invoiceData[i].name = invoices[i].fromName;
    //     invoiceData[i].id = invoices[i]._id;
    //     invoiceData[i].start = invoices[i].createdAt;
    //     invoiceData[i].end = invoices[i].updatedAt;
    //     invoiceData[i].state = invoices[i].status;
    //     invoiceData[i].assignee = invoices[i].toName;
    //   }
    // } else {
    //   invoices.map((i) => {
    //     invoiceData[i].name = invoices[i].fromName;
    //     invoiceData[i].id = invoices[i]._id;
    //     invoiceData[i].start = invoices[i].createdAt;
    //     invoiceData[i].end = invoices[i].updatedAt;
    //     invoiceData[i].assignee = invoices[i].toName;
    //   });
    // }
  }, [props]);
  const invoiceStatus = (status) => {
    //console.log(status === "notPaid");
    if (status === "notpaid") {
      return "Pending";
    }
    if (status === "paid") {
      return "Paid";
    }
    if (status === "cancelled") {
      return "Cancelled";
    }
    return status;
  };
  const setChipColor = (status) => {
    if (status === "Paid") {
      return "success";
    } else if (status === "Cancelled") {
      return "error";
    } else {
      return "warning";
    }
  };
  return (
    <Card mb={6}>
      <CardHeader
        action={
          <LinkRRD style={{ color: "white" }} to={`/admin/invoices`}>
            <Typography>View all Invoices</Typography>
          </LinkRRD>
        }
        title="Recent Top 5 invoices"
      />
      <Paper>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceData.slice(0, 5).map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    <LinkRRD
                      style={{ color: "white", textDecoration: "none" }}
                      to={"/admin/profile/" + row.to[0].toId}
                    >
                      {row.to[0].toName}
                    </LinkRRD>
                  </TableCell>
                  <TableCell>{row.to[0].toEmail}</TableCell>
                  <TableCell>{"$ " + row.total.toFixed(2)}</TableCell>
                  <TableCell>
                    {new Date(row.createdAt).toDateString()}
                  </TableCell>
                  <TableCell>
                    {/* {row.status} */}
                    <Chip
                      label={invoiceStatus(row.status)}
                      style={{
                        textTransform: "capitalize",
                        color: "#1e1b1b",
                        fontWeight: "10px",
                      }}
                      color={setChipColor(invoiceStatus(row.status))}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
};

export default DashboardTable;
