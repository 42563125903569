import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import http from "../../../utils/http";

import {
  Grid,
  Link,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Autocomplete,
  TextField,
  Stack,
  Box,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { fetchUsers } from "../../../redux/slices/user";
import { selectTour, getTour } from "../../../redux/slices/tour";
import Swal from "sweetalert2";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is Required!"),
  userId: Yup.string().required("User is Required!"),
  invoiceId: Yup.string().required("Invoice is Required!"),
  subdomain: Yup.string().required("Sub Domain Name is Required!"),
  folderName: Yup.string().required("Folder Name is Required!"),
});

function EditVT() {
  const appDispatch = useAppDispatch();
  const userList = useSelector((state) => state.user.users); // fetching users list from user slice
  const [optionUsers, setOptionUsers] = useState([]);
  const [optionInvoices, setOptionInvoices] = useState([]);
  const [initialValues, setInitialValues] = useState({
    title: "",
    userId: "",
    invoiceId: "",
    subdomain: "",
    folderName: "",
  });
  const [currentUserId, setCurrentUserId] = useState("");
  const { tour } = useSelector(selectTour);
  const { id } = useParams();
  //console.log("optionInvoices", optionInvoices);

  useEffect(() => {
    appDispatch(fetchUsers());
    appDispatch(getTour(id));
  }, [appDispatch, id]);

  //console.log(tour);
  useEffect(() => {
    let totalUsers = [];
    // fetching user name only from each user object of userList array
    for (let user of userList) {
      totalUsers.push({ ...user, label: user.name });
    }
    totalUsers.pop();
    setOptionUsers(totalUsers); // updating the state
  }, [userList]);

  useEffect(async () => {
    // checking while dispatching and if user null
    const tempInitialValues = { ...initialValues };

    //console.log("TOUR", tour);

    if (tour && Object.keys(tour).length > 0) {
      tempInitialValues.title = tour.title;
      tempInitialValues.userId = tour.userId.name;
      tempInitialValues.invoiceId = tour.invoiceId._id;
      tempInitialValues.subdomain = tour.subdomain;
      tempInitialValues.folderName = tour.folderName;

      setInitialValues(tempInitialValues);

      if (tour.userId !== "" && tour.userId._id) {
        setCurrentUserId(tour.userId._id);
        const response = await http.get(`/invoice/user/${tour.userId._id}`);
        setOptionInvoices(response.data.invoices);
        //console.log(tour);
      }
      //console.log("RENDERING");
      //console.log("tempInitialValues", tempInitialValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour]);
  // useEffect(() => {
  //   const tempInitialValues = { ...initialValues };
  //   Object.keys(tempInitialValues).forEach((item) => {
  //     tempInitialValues[item] = tour[item];
  //   });
  //   setInitialValues(tempInitialValues);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tour]);
  //console.log(initialValues);
  const handleUserChChange = async (e) => {
    //console.log("e", e);
    setCurrentUserId(e);
    try {
      const response = await http.get(`/invoice/user/${e}`);
      setOptionInvoices(response.data.invoices);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    const tourToSend = {
      ...values,
      userId: currentUserId,
    };
    //console.log("SUBMIT VALUESS", tourToSend);
    try {
      const response = await http.put("/tours/edit/" + tour._id, tourToSend);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "VT updated successfuly",
        });
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong",
      });
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Edit Tours" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Virtual Tour
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/admin/virtual-tours">
          Manage Tours
        </Link>

        <Typography>Edit Virtual Tour</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Stack>
        {/* Autocomplete outside of form */}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            setFieldValue,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            // <Card mb={6}>
            //   <CardContent>
            //     <Typography variant="h6" gutterBottom>
            //       Upload VT
            //     </Typography>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Edit a Virtual Tour
                </Typography>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                      <Grid item container spacing={4}>
                        <Grid item xs={12}>
                          <Autocomplete
                            onChange={(e, value) => {
                              handleUserChChange(value?._id);
                              return setFieldValue("userId", value?.name);
                            }} // prints the selected value
                            disablePortal
                            id="combo-box-demo"
                            options={optionUsers}
                            value={values.userId}
                            disabled
                            renderInput={(params) => (
                              <TextField
                                name="userCh"
                                disabled
                                // onChange={handleUserChChange}
                                {...params}
                                label="Select User"
                                type="text"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="invoiceId">
                              Select Invoice
                            </InputLabel>
                            <Select
                              labelId="invoiceId"
                              id="incoiceId"
                              value={values.invoiceId}
                              disabled
                              label="Select Invoice"
                              name="invoiceId"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            >
                              {optionInvoices.map((invoice, i) => {
                                return (
                                  <MenuItem value={invoice._id} key={i}>
                                    {invoice._id}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={4}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            name="title"
                            label="Title"
                            value={values.title}
                            error={Boolean(touched.title && errors.title)}
                            fullWidth
                            helperText={touched.title && errors.title}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            name="subdomain"
                            label="Enter Subdomain name"
                            value={values.subdomain}
                            error={touched.subdomain && errors.subdomain}
                            fullWidth
                            helperText={touched.subdomain && errors.subdomain}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            name="folderName"
                            label="Enter Folder Name"
                            value={values.folderName}
                            error={touched.folderName && errors.folderName}
                            fullWidth
                            helperText={touched.folderName && errors.folderName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            variant="outlined"
                            my={2}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container alignItems="center">
                        <Typography variant="h4" align="center">
                          {values.subdomain &&
                            values.title &&
                            `${
                              values.title[0].toUpperCase() +
                              values.title.slice(1)
                            }'s virtual tour will go live on: 
                            https://${values.subdomain}.thiscvr.com`}
                          {values.folderName && `/${values.folderName}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      style={{ margin: "5px" }}
                    >
                      Save changes
                    </Button>
                  </form>
                )}
              </CardContent>
            </Card>
          )}
        </Formik>
      </Stack>
    </React.Fragment>
  );
}

export default EditVT;
