import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";

const initialState = {
  loading: true,
  analytics: [],
  monthlyanalysis: [],
  platform: [],
  geoLocation: [],
  language: [],
  source: [],
  button: "Last 30 days",
  error: "",
};

export const getUserAnalytics = createAsyncThunk(
  "google/getUserAnalytics",
  async (value = "30daysAgo") => {
    // console.log(value);

    const response = await http.get(`/useranalytics?value=${value}`);
    return response.data;
  }
);

export const analyticsUserSlice = createSlice({
  name: "GoogleAnalyticsUser",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    buttonName: (state, param) => {
      const { payload } = param;
      state.button = payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // get data
      .addCase(getUserAnalytics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.analytics = action.payload.analytics;
        state.monthlyanalysis = action.payload.monthlyanalysis;
        state.platform = action.payload.platform;
        state.geoLocation = action.payload.geoLocation;
        state.source = action.payload.source;
        state.language = action.payload.language;
      })
      .addCase(getUserAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = [];
      });
  },
});

const { actions, reducer } = analyticsUserSlice;
export const { buttonName } = actions;
export default reducer;
