import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const SignOut = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  useEffect(() => {
    const run = async () => {
      try {
        await signOut();
        navigate("/auth/sign-in");
      } catch {
        navigate("/");
      }
    };

    run();
  }, [navigate, signOut]);

  return <div></div>;
};

export default SignOut;
