import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../utils/http";
import { isValidToken, setSession } from "../../utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,

  status: "initial",
};

// A mock function to mimic making an async request for data
// export function fetchUser(amount = 1) {
//   return new Promise((resolve) =>
//     setTimeout(() => resolve({ data: amount }), 500)
//   );
// }

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const initialize = createAsyncThunk("jwt/initialize", async () => {
  try {
    const accessToken = window.localStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {
      setSession(accessToken);
      //console.log("accessToken", isValidToken(accessToken));

      const response = await http.get("/auth/user");
      const { user } = response.data;

      return {
        isAuthenticated: true,
        user,
      };
    } else {
      return {
        isAuthenticated: false,
        user: null,
      };
    }
  } catch (err) {
    // console.error(err);
    return {
      isAuthenticated: false,
      user: null,
    };
  }
});

export const signIn = createAsyncThunk(
  "jwt/signIn",
  async ({ email, password }) => {
    const response = await http.post("/auth/login", {
      email,
      password,
    });

    //console.log(response);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const jwtSlice = createSlice({
  name: "jwt",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // signUp: (state, action) => {
    //   state.isAuthenticated = true;
    //   state.user = action.payload.user;
    // },
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(initialize.pending, (state) => {
        state.status = "loading";
      })
      .addCase(initialize.fulfilled, (state, action) => {
        const { user, isAuthenticated } = action.payload;

        state.isAuthenticated = isAuthenticated;
        state.isInitialized = true;
        state.user = user;
        state.status = isAuthenticated ? "idle" : null;
      })
      .addCase(initialize.rejected, (state, action) => {
        const { isAuthenticated } = action.payload;
        state.status = null;
        state.isAuthenticated = isAuthenticated;
      })
      // signIn starts
      .addCase(signIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        const { accessToken, user } = action.payload;
        state.status = "idle";
        state.isAuthenticated = true;
        state.user = user;

        //console.log(accessToken);
        setSession(accessToken);
      })
      .addCase(signIn.rejected, (state) => {
        state.isAuthenticated = false;
        state.user = null;
        state.status = null;
      });
  },
});

// export const {} = jwtSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectJWT = (state) => state.jwt;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default jwtSlice.reducer;
