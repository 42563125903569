import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
import { getUserInvoices } from "../../redux/slices/invoice";
import { Briefcase, Edit, Home, Phone } from "react-feather";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@mui/material";
import { fetchInvoicesByUserId } from "../../redux/slices/invoice";
import { spacing } from "@mui/system";
import http from "../../utils/http";
import useAuth from "../../hooks/useAuth";
import { updateUser } from "../../redux/slices/user";

import { Link as LinkRRD } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  FormControlLabel,
  Toolbar,
  Button,
  Chip as MuiChip,
  IconButton,
} from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
  fetchAllTours,
  selectUserTours,
  fetchUserTours,
} from "../../redux/slices/tour";
import ThreeDotMenu from "../../components/menu/ThreeDotMenu";
// import TourforUser from "./TourforUser";
import {
  Archive as ArchiveIcon,
  Email,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import { green, orange } from "@mui/material/colors";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

// const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

// const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

// const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

// const Centered = styled.div`
//   text-align: center;
// `;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

// const ChartWrapper = styled.div`
//   height: 280px;
//   position: relative;
// `;

// const StatsIcon = styled.div`
//   position: absolute;
//   right: 16px;
//   top: 32px;

//   svg {
//     width: 32px;
//     height: 32px;
//     color: ${(props) => props.theme.palette.secondary.main};
//   }
// `;

// const ProductsChip = styled(Chip)`
//   height: 20px;
//   padding: 4px 0;
//   font-size: 90%;
//   background-color: ${(props) =>
//     props.theme.palette[props.color ? props.color : "primary"].light};
//   color: ${(props) => props.theme.palette.common.white};
// `;

// const TableWrapper = styled.div`
//   overflow-y: auto;
//   max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
// `;
//invoices

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

// function createData(
//   customer,
//   customerEmail,
//   customerAvatar,
//   status,
//   amount,
//   id,
//   date
// ) {
//   return { customer, customerEmail, customerAvatar, status, amount, id, date };
// }

// const ToolbarTitle = styled.div`
//   min-width: 150px;
// `;

const headCells1 = [
  {
    id: "toName",
    numeric: false,
    disablePadding: true,
    label: "Customer",
  },
  {
    id: "count",
    numeric: false,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "invoiceFor",
    numeric: false,
    disablePadding: false,
    label: "Period",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    menus: [
      // { label: "Details", link: "", target: "blabk" },
      { label: "Edit", link: "/" },
      // { label: "Delete", link: "/" },
    ],
  },
];

const populateMenusLink1 = ([menus, edit]) => {
  const newMenus = [{ ...menus[0] }];
  newMenus[0].link = edit;
  return newMenus;
};

const EnhancedTableHead1 = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells1.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar1 = (props) => {
  // Here was 'let'
  const { numSelected } = props;

  return (
    <Toolbar>
      <div>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" width="100px" id="tableTitle">
            Invoice Lists
          </Typography>
        )}
      </div>
      <Spacer />
      {/* <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div> */}
    </Toolbar>
  );
};

function EnhancedTable1() {
  // const appDispatch = useAppDispatch();
  // invoices = invoices || [];
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const invoices = useSelector(getUserInvoices);

  //console.log({ invoices });
  //console.log("invoices", invoices);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const getInvoicefor = (period) => {
    if (period === 1) {
      return "One Time";
    } else if (period === 7) {
      return "Weekly";
    } else if (period === 30) {
      return "Monthly";
    } else if (period === 365) {
      return period + "Yearly";
    }
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = invoices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, invoices.length - page * rowsPerPage);

  const returnStatusWithColor = (status) => {
    //console.log("STATUS", status);
    switch (status) {
      case "awaiting":
        return (
          <Chip
            size="small"
            sx={{
              backgroundColor: "yellow",
              color: "black",
              fontWeight: "600",
            }}
            label="Awaiting"
          ></Chip>
        );
      case "notPaid":
        return (
          <Chip
            size="small"
            sx={{
              backgroundColor: "orange",
              color: "black",
              fontWeight: "600",
            }}
            label="Not Paid"
          ></Chip>
        );
      case "paid":
        return (
          <Chip
            size="small"
            sx={{ backgroundColor: "green", color: "white", fontWeight: "600" }}
            label="Paid"
          ></Chip>
        );
      case "cancelled":
        return (
          <Chip
            size="small"
            sx={{ backgroundColor: "red", color: "black", fontWeight: "600" }}
            label="Cancelled"
          ></Chip>
        );
      default:
        return "unknown";
    }
  };

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar1 numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead1
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={invoices.length}
            />
            <TableBody>
              {invoices.length ? (
                stableSort(invoices, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    // const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    //console.log(row);
                    return (
                      <TableRow
                      // hover
                      // onClick={(event) => handleClick(event, row._id)}
                      // role="checkbox"
                      // aria-checked={isItemSelected}
                      // tabIndex={-1}
                      // key={row.name}
                      // selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}
                        {headCells1.map((cell, index) => {
                          if (cell.id === "toName") {
                            return (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding=""
                                align={"left"}
                              >
                                {!index && (
                                  <>
                                    <LinkRRD
                                      style={{
                                        textDecoration: "none",
                                        color: "white",
                                      }}
                                      to={
                                        "/admin/profile/" +
                                        row["to"][index].toId
                                      }
                                    >
                                      {row["to"].map((item) => {
                                        return (
                                          <div key={item.toId}>
                                            <Customer>
                                              <Avatar
                                                style={{
                                                  height: "50px",
                                                  width: "50px",
                                                  display: "flex",
                                                }}
                                              >
                                                {item.toName.charAt(0)}
                                              </Avatar>
                                              <Box ml={3}>
                                                {item.toName}
                                                <br />
                                                {item.toEmail}
                                              </Box>
                                            </Customer>
                                          </div>
                                        );
                                      })}
                                    </LinkRRD>
                                  </>
                                )}
                                {/* {index ? <>{toNamesRow}</> : null} */}
                                {cell.id === "action" && (
                                  <ThreeDotMenu
                                    menus={populateMenusLink1([
                                      cell.menus,
                                      // `/admin/invoice/details/${row._id}`,
                                      `/admin/invoice/edit/${row._id}`,
                                      // () => {
                                      //   appDispatch(deleteInvoice(row._id));
                                      // },
                                    ])}
                                  />
                                )}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding={!index ? "none" : ""}
                              align={"left"}
                            >
                              {/* {//console.log("ROWWWW", row)} */}
                              {cell.id === "status" &&
                                returnStatusWithColor(row[cell.id])}
                              {cell.id === "count" && (
                                <LinkRRD
                                  style={{
                                    textDecoration: "none",
                                  }}
                                  to={"/invoices/" + row._id}
                                >
                                  <Typography
                                    style={{
                                      color: "white",
                                      textDecoration: "none",
                                    }}
                                  >
                                    # {row.count}
                                  </Typography>
                                </LinkRRD>
                              )}
                              {!index && <>{row[cell.id]}</>}
                              {cell.id === "invoiceFor" && index ? (
                                <>{getInvoicefor(row[cell.id])}</>
                              ) : null}
                              {cell.id === "date" ? (
                                <>
                                  {new Date(row[cell.id])
                                    .toUTCString()
                                    .slice(0, 17)}
                                </>
                              ) : null}
                              {cell.id === "rate" ? (
                                <>{"$" + row.total.toFixed(2)}</>
                              ) : null}
                              {/* {cell.id === "link" ? (
                              <>
                                {
                                  <IconButton
                                    aria-label="details"
                                    // component={Download}
                                    href={row.invoiceUrl}
                                    size="large"
                                  >
                                    <Download />
                                  </IconButton>
                                }
                              </>
                            ) : null} */}
                              {cell.id === "action" && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <IconButton
                                    aria-label="details"
                                    // component={Download}
                                    href={row.invoiceUrl}
                                    size="large"
                                  >
                                    <ArchiveIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="edit"
                                    component={NavLink}
                                    to={`/admin/invoice/edit/${row._id}`}
                                    size="large"
                                  >
                                    <Edit />
                                  </IconButton>
                                  <IconButton
                                    aria-label="details"
                                    component={NavLink}
                                    to={`/invoices/${row._id}`}
                                    size="large"
                                  >
                                    <RemoveRedEyeIcon />
                                  </IconButton>
                                </div>
                              )}
                            </TableCell>
                          );
                        })}
                        {/* <TableCell
                        key={index}
                        component="th"
                        scope="row"
                        padding={""}
                        align={"right"}
                      >
                        <CreateInvoiceFormDialog
                          text="Create Invoice"
                          userId={row?._id}
                        />
                      </TableCell> */}
                      </TableRow>
                    );
                  })
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ width: "100%" }}
                  margin="10px"
                >
                  <Grid item xs>
                    <Typography
                      variant="h5"
                      align="right"
                      fluid
                      width={"full"}
                      noWrap
                    >
                      No Invoice Available
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={invoices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
// function Details({ user }) {
//   const [isAdmin, setIsAdmin] = useState(false);
//   const [isActive, setIsActive] = useState(false);
//   const appDispatch = useAppDispatch();
//   useEffect(() => {
//     if (user.role === "admin") setIsAdmin(true);
//     if (user.status === "active") setIsActive(true);
//   }, [user.role, user.status]);

//   const handleAdminChange = async () => {
//     const newRole = isAdmin ? "user" : "admin";
//     const newRoleState = !isAdmin;
//     setIsAdmin(newRoleState);
//     let userRole = "admin";
//     const id = user._id;
//     //console.log(id);
//     //console.log(user.status);
//     if (user.role === "admin") {
//       userRole = "user";
//     } else if (user.role === "user") {
//       userRole = "admin";
//     }
//     try {
//       const values = {
//         role: userRole,
//       };
//       appDispatch(updateUser({ id, values }));
//       //console.log(data);
//       Swal.fire({
//         icon: "success",
//         title: "Success",
//         text: "Role change succesfully",
//       });
//     } catch (error) {
//       //console.log(error);
//       //console.log(isAdmin);
//       setIsAdmin(!newRoleState);
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: error.msg,
//       });
//     }
//   };

//   const handleStatusChange = async () => {
//     const newStatus = isActive ? "blocked" : "active";
//     const newStatusState = !isActive;
//     setIsActive(newStatusState);
//     let userstatus = "active";
//     const id = user._id;
//     //console.log(id);
//     //console.log(user.status);
//     if (user.status === "active") {
//       userstatus = "inactive";
//     } else if (user.status === "inactive") {
//       userstatus = "active";
//     }
//     //console.log(userstatus);
//     try {
//       const values = {
//         status: userstatus,
//       };
//       appDispatch(updateUser({ id, values }));
//       //console.log(data);
//       Swal.fire({
//         icon: "success",
//         title: "Success",
//         text: "user Status change",
//       });
//     } catch (error) {
//       //console.log(error);
//       //console.log(isActive);
//       setIsActive(!newStatusState);
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: error.msg,
//       });
//     }
//   };

//   return (
//     <Card mb={6}>
//        <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Profile Details
//         </Typography>

//        <Spacer mb={4} />

//         <Centered>
//           <Avatar alt="Lucy Lavender" src={user.img} />
//           <Typography variant="body2" component="div" gutterBottom>
//             <Box fontWeight="fontWeightMedium">@{user.name}</Box>
//             <Box fontWeight="fontWeightRegular">
//               Company: {user.companyName}
//             </Box>
//             <Box fontWeight="fontWeightRegular">Email: {user.email}</Box>
//             <Box fontWeight="fontWeightRegular">Phone: {user.phone}</Box>
//             <Grid component="label" container alignItems="center" spacing={1}>
//               <Grid item>User</Grid>
//               <Grid item>
//                 <Switch
//                   checked={isAdmin} // relevant state for your case
//                   onChange={handleAdminChange} // relevant method to handle your change
//                   value="checked" // some value you need
//                 />
//               </Grid>
//               <Grid item>Admin</Grid>
//             </Grid>
//             <Grid component="label" container alignItems="center" spacing={1}>
//               <Grid item>Blocked</Grid>
//               <Grid item>
//                 <Switch
//                   checked={isActive} // relevant state for your case
//                   onChange={handleStatusChange} // relevant method to handle your change
//                   value="checked" // some value you need
//                 />
//               </Grid>
//               <Grid item>Active</Grid>
//             </Grid>
//           </Typography>

//           <Button mr={2} variant="contained" color="primary" size="small">
//             Follow
//           </Button>
//           <Button mr={2} variant="contained" color="primary" size="small">
//             Message
//           </Button>
//         </Centered>
//       </CardContent>
//   </Card>
//   );
// }

// function Skills() {
//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Skills
//         </Typography>

//         <Spacer mb={4} />

//         <Centered>
//           <Chip size="small" mr={1} mb={1} label="HTML" color="secondary" />
//           <Chip size="small" mr={1} mb={1} label="JavaScript" />
//           <Chip size="small" mr={1} mb={1} label="Sass" />
//           <Chip size="small" mr={1} mb={1} label="React" />
//           <Chip size="small" mr={1} mb={1} label="Redux" />
//           <Chip size="small" mr={1} mb={1} label="Next.js" />
//           <Chip size="small" mr={1} mb={1} label="Material UI" />
//           <Chip size="small" mr={1} mb={1} label="UI" />
//           <Chip size="small" mr={1} mb={1} label="UX" />
//         </Centered>
//       </CardContent>
//     </Card>
//   );
// }

function About({ user }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const appDispatch = useAppDispatch();
  useEffect(() => {
    if (user.role === "admin") setIsAdmin(true);
    if (user.status === "active") setIsActive(true);
  }, [user.role, user.status]);

  const handleAdminChange = async () => {
    // const newRole = isAdmin ? "user" : "admin";
    const newRoleState = !isAdmin;
    setIsAdmin(newRoleState);
    let userRole = "admin";
    const id = user._id;
    //console.log(id);
    //console.log(user.status);
    if (user.role === "admin") {
      userRole = "user";
    } else if (user.role === "user") {
      userRole = "admin";
    }
    try {
      const values = {
        role: userRole,
      };
      appDispatch(updateUser({ id, values }));
      //console.log(data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Role change succesfully",
      });
    } catch (error) {
      //console.log(error);
      //console.log(isAdmin);
      setIsAdmin(!newRoleState);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.msg,
      });
    }
  };

  const handleStatusChange = async () => {
    // const newStatus = isActive ? "blocked" : "active";
    const newStatusState = !isActive;
    setIsActive(newStatusState);
    let userstatus = "active";
    const id = user._id;
    //console.log(id);
    //console.log(user.status);
    if (user.status === "active") {
      userstatus = "inactive";
    } else if (user.status === "inactive") {
      userstatus = "active";
    }
    //console.log(userstatus);
    try {
      const values = {
        status: userstatus,
      };
      appDispatch(updateUser({ id, values }));
      //console.log(data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "user Status change",
      });
    } catch (error) {
      //console.log(error);
      //console.log(isActive);
      setIsActive(!newStatusState);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.msg,
      });
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          About
        </Typography>

        <Spacer mb={4} />
        <Grid item>
          <Avatar
            alt="Lucy Lavender"
            src={user.img}
            style={{
              width: "50px",
              height: "50px",
              display: "flex",
              padding: "10px ",
              margin: "10px 0px",
            }}
          />

          <Box fontWeight="fontWeightMedium">user : {user.name}</Box>
        </Grid>
        <Spacer mb={1} />
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Home />
            </AboutIcon>
          </Grid>
          <Grid item>
            Lives in <Link href="https://mira.bootlab.io/">{user.address}</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Briefcase />
            </AboutIcon>
          </Grid>
          <Grid item>
            Works at{" "}
            <Link href="https://mira.bootlab.io/">{user.companyName}</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Phone />
            </AboutIcon>
          </Grid>
          <Grid item>
            Contact: <Link href="https://mira.bootlab.io/">{user.phone}</Link>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" mb={2}>
          <Grid item>
            <AboutIcon>
              <Email />
            </AboutIcon>
          </Grid>
          <Grid item>
            Email: <Link href="https://mira.bootlab.io/">{user.email}</Link>
          </Grid>
        </Grid>
        <Grid component="label" container alignItems="center" spacing={0.5}>
          <Grid item>User</Grid>
          <Grid item>
            <Switch
              checked={isAdmin} // relevant state for your case
              onChange={handleAdminChange} // relevant method to handle your change
              value="checked" // some value you need
            />
          </Grid>
          <Grid item>Admin</Grid>
        </Grid>
        <Grid component="label" container alignItems="center" spacing={0.5}>
          <Grid item>Blocked</Grid>
          <Grid item>
            <Switch
              checked={isActive} // relevant state for your case
              onChange={handleStatusChange} // relevant method to handle your change
              value="checked" // some value you need
            />
          </Grid>
          <Grid item>Active</Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "title",
    numeric: false,
    disablePadding: true,
    label: "Title",
  },
  {
    id: "invoiceId",
    numeric: false,
    disablePadding: false,
    label: "Invoice ID",
  },
  {
    id: "userId",
    numeric: false,
    disablePadding: false,
    label: "User Name",
  },
  {
    id: "link",
    numeric: false,
    disablePadding: false,
    label: "Tour",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    menus: [
      // { label: "Details", link: "/" },
      { label: "Edit", link: "/" },
      { label: "Delete", link: "/" },
    ],
  },
];

const populateMenusLink = ([menus, edit, delFunc]) => {
  const newMenus = [{ ...menus[0] }, { ...menus[1] }];
  newMenus[0].link = edit;
  newMenus[1].delete = delFunc;
  return newMenus;
};

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected } = props;

  return (
    <Toolbar>
      <div>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle" width="100px">
            Tours List
          </Typography>
        )}
      </div>
      <Spacer />
      {/* <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div> */}
    </Toolbar>
  );
};

function EnhancedTable({ data: tours }) {
  const appDispatch = useAppDispatch();
  // users = users || [];
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [toursList, setToursList] = React.useState([]);

  useEffect(() => {
    setToursList(tours);
    //console.log("RENDERING");
  }, [tours]);
  //console.log({ toursList, tours });
  //console.log(
  //   "TOURS------",
  //   stableSort(toursList, getComparator(order, orderBy)).slice(
  //     page * rowsPerPage,
  //     page * rowsPerPage + rowsPerPage
  //   )
  // );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = toursList.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, toursList.length - page * rowsPerPage);

  const deleteTour = async (id) => {
    if (!id) return;
    try {
      await http.delete(`/tours/${id}`);
      const tourDeleted = toursList.filter((tour) => tour._id !== id);
      setToursList(tourDeleted);
    } catch (error) {
      //console.log("error");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete VT",
      });
    }
  };

  return (
    <div>
      <Paper>
        {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }} pt={2} pr={2}>
          <RRDLink style={{ textDecoration: "none" }} to="/admin/upload-tours">
            <Button variant="contained" color="primary" component="span">
              Upload VT
            </Button>
          </RRDLink>
        </Box> */}
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={toursList.length}
            />
            <TableBody>
              {toursList.length ? (
                stableSort(toursList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    // const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        key={index}
                        // onClick={(event) => handleClick(event, row?._id)}
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        // tabIndex={-1}
                        // // key={row.name}
                        // key={Math.random()}
                        // selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row?._id)}
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell> */}
                        {headCells.map((cell, index) => (
                          <TableCell
                            key={index}
                            component="th"
                            id={labelId}
                            scope="row"
                            // padding={!index ? "none" : "normal"}
                            align={cell.numeric ? "right" : "left"}
                          >
                            {/* {//console.log("ROWW", row)} */}
                            {/* {//console.log("CELL", cell)} */}
                            {/* {cell.id !== "userId" && row[cell.id].id} */}
                            {/* {cell.id === "userId" && row[cell.id].name} */}
                            {cell.id === "userId" && (
                              <>
                                <LinkRRD
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontWeight: "800",
                                  }}
                                  relative="path"
                                  to={"/admin/user/edit/" + row[cell.id]._id}
                                >
                                  {row[cell.id].name}
                                </LinkRRD>
                              </>
                            )}
                            {cell.id === "link" && (
                              <Button>
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontWeight: "800",
                                  }}
                                  href={`https://${row.subdomain}.thiscvr.com/${
                                    row.folderName || ""
                                  }`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Visit
                                </a>
                              </Button>
                            )}
                            {/* {//console.log("CELL", cell)} */}
                            {cell.id === "invoiceId" && (
                              <>
                                <LinkRRD
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontWeight: "800",
                                  }}
                                  to={"/admin/invoice/edit/" + row[cell.id]._id}
                                >
                                  #{row[cell.id].count}
                                </LinkRRD>
                              </>
                            )}
                            <Typography textTransform="capitalize">
                              {cell.id === "title" && row[cell.id]}
                            </Typography>
                            {cell.id === "action" && (
                              <ThreeDotMenu
                                menus={populateMenusLink([
                                  cell.menus,
                                  `/admin/vt/edit/${row._id}`,
                                  () => {
                                    deleteTour(row._id);
                                    appDispatch(fetchAllTours());
                                  },
                                ])}
                              />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ width: "100%" }}
                  margin="10px"
                >
                  <Grid item xs>
                    <Typography
                      variant="h5"
                      align="center"
                      fluid
                      width={"full"}
                      noWrap
                    >
                      No Virtual Tours Available
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={toursList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

// function Elsewhere() {
//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Elsewhere
//         </Typography>

//         <Spacer mb={4} />

//         <Grid container direction="row" alignItems="center" mb={2}>
//           <Grid item>
//             <AboutIcon>
//               <ExternalLink />
//             </AboutIcon>
//           </Grid>
//           <Grid item>
//             <Link href="https://mira.bootlab.io/">lucylavender.io</Link>
//           </Grid>
//         </Grid>
//         <Grid container direction="row" alignItems="center" mb={2}>
//           <Grid item>
//             <AboutIcon>
//               <Twitter />
//             </AboutIcon>
//           </Grid>
//           <Grid item>
//             <Link href="https://mira.bootlab.io/">Twitter</Link>
//           </Grid>
//         </Grid>
//         <Grid container direction="row" alignItems="center" mb={2}>
//           <Grid item>
//             <AboutIcon>
//               <Facebook />
//             </AboutIcon>
//           </Grid>
//           <Grid item>
//             <Link href="https://mira.bootlab.io/">Facebook</Link>
//           </Grid>
//         </Grid>
//         <Grid container direction="row" alignItems="center">
//           <Grid item>
//             <AboutIcon>
//               <Instagram />
//             </AboutIcon>
//           </Grid>
//           <Grid item>
//             <Link href="https://mira.bootlab.io/">Instagram</Link>
//           </Grid>
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// }

// function Earnings() {
//   return (
//     <Box position="relative">
//       <Card mb={6} pt={2}>
//         <CardContent>
//           <Typography variant="h2" gutterBottom>
//             <Box fontWeight="fontWeightRegular">$ 2.405</Box>
//           </Typography>
//           <Typography variant="body2" gutterBottom mt={3} mb={0}>
//             Total Earnings
//           </Typography>

//           <StatsIcon>
//             <DollarSign />
//           </StatsIcon>
//           <LinearProgress
//             variant="determinate"
//             value={75}
//             color="secondary"
//             mt={4}
//           />
//         </CardContent>
//       </Card>
//     </Box>
//   );
// }

// function Orders() {
//   return (
//     <Box position="relative">
//       <Card mb={6} pt={2}>
//         <CardContent>
//           <Typography variant="h2" gutterBottom>
//             <Box fontWeight="fontWeightRegular">30</Box>
//           </Typography>
//           <Typography variant="body2" gutterBottom mt={3} mb={0}>
//             Orders Today
//           </Typography>

//           <StatsIcon>
//             <ShoppingBag />
//           </StatsIcon>
//           <LinearProgress
//             variant="determinate"
//             value={30}
//             color="secondary"
//             mt={4}
//           />
//         </CardContent>
//       </Card>
//     </Box>
//   );
// }

// function Revenue() {
//   return (
//     <Box position="relative">
//       <Card mb={6} pt={2}>
//         <CardContent>
//           <Typography variant="h2" gutterBottom>
//             <Box fontWeight="fontWeightRegular">$ 1.224</Box>
//           </Typography>
//           <Typography variant="body2" gutterBottom mt={3} mb={0}>
//             Total Revenue
//           </Typography>

//           <StatsIcon>
//             <DollarSign />
//           </StatsIcon>
//           <LinearProgress
//             variant="determinate"
//             value={50}
//             color="secondary"
//             mt={4}
//           />
//         </CardContent>
//       </Card>
//     </Box>
//   );
// }

// function Products() {
//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Products
//         </Typography>
//         <TableWrapper>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Name</TableCell>
//                 <TableCell>Tech</TableCell>
//                 <TableCell>License</TableCell>
//                 <TableCell>Sales</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               <TableRow>
//                 <TableCell component="th" scope="row">
//                   AppStack
//                 </TableCell>
//                 <TableCell>
//                   <ProductsChip size="small" label="HTML" color="primary" />
//                 </TableCell>
//                 <TableCell>Single License</TableCell>
//                 <TableCell>76</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell component="th" scope="row">
//                   Mira
//                 </TableCell>
//                 <TableCell>
//                   <ProductsChip size="small" label="React" color="success" />
//                 </TableCell>
//                 <TableCell>Single License</TableCell>
//                 <TableCell>38</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell component="th" scope="row">
//                   Milo
//                 </TableCell>
//                 <TableCell>
//                   <ProductsChip size="small" label="HTML" color="primary" />
//                 </TableCell>
//                 <TableCell>Single License</TableCell>
//                 <TableCell>43</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell component="th" scope="row">
//                   Robust UI Kit
//                 </TableCell>
//                 <TableCell>
//                   <ProductsChip size="small" label="Angular" color="error" />
//                 </TableCell>
//                 <TableCell>Single License</TableCell>
//                 <TableCell>27</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell component="th" scope="row">
//                   Spark
//                 </TableCell>
//                 <TableCell>
//                   <ProductsChip size="small" label="React" color="success" />
//                 </TableCell>
//                 <TableCell>Single License</TableCell>
//                 <TableCell>12</TableCell>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </TableWrapper>
//       </CardContent>
//     </Card>
//   );
// }

// const SalesRevenue = withTheme(({ theme }) => {
//   const data = {
//     labels: [
//       "Jan",
//       "Feb",
//       "Mar",
//       "Apr",
//       "May",
//       "Jun",
//       "Jul",
//       "Aug",
//       "Sep",
//       "Oct",
//       "Nov",
//       "Dec",
//     ],
//     datasets: [
//       {
//         label: "Sales",
//         backgroundColor: theme.palette.secondary.main,
//         borderColor: theme.palette.secondary.main,
//         hoverBackgroundColor: theme.palette.secondary.main,
//         hoverBorderColor: theme.palette.secondary.main,
//         data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
//         barPercentage: 0.625,
//         categoryPercentage: 0.5,
//       },
//       {
//         label: "Revenue",
//         backgroundColor: theme.palette.grey[200],
//         borderColor: theme.palette.grey[200],
//         hoverBackgroundColor: theme.palette.grey[200],
//         hoverBorderColor: theme.palette.grey[200],
//         data: [69, 66, 24, 48, 52, 51, 44, 53, 62, 79, 51, 68],
//         barPercentage: 0.625,
//         categoryPercentage: 0.5,
//       },
//     ],
//   };

//   const options = {
//     maintainAspectRatio: false,
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },
//     scales: {
//       y: {
//         grid: {
//           display: false,
//         },
//         stacked: false,
//       },

//       x: {
//         stacked: false,
//         grid: {
//           color: "transparent",
//         },
//       },
//     },
//   };

//   return (
//     <Card mb={6}>
//       <CardContent>
//         <Typography variant="h6" gutterBottom>
//           Sales / Revenue
//         </Typography>

//         <Spacer mb={6} />

//         <ChartWrapper>
//           <Chart type="bar" data={data} options={options} />
//         </ChartWrapper>
//       </CardContent>
//     </Card>
//   );
// });

//invoices

function UserProfile() {
  // const user = useSelector((state) => selectUser(state));
  const [user, setUser] = useState({});
  const { id } = useParams();
  const { isAuthenticated } = useAuth();
  const tours = useSelector(selectUserTours); //fetching the list of banks from redux store
  // const banks = [];
  const appDispatch = useAppDispatch();

  //console.log({ id });

  useEffect(() => {
    if (id) {
      appDispatch(fetchInvoicesByUserId(id));
      appDispatch(fetchUserTours(id));
    }
    const getUser = async () => {
      try {
        const { data } = await http.get("/auth/users/" + id);
        setUser(data.user);
      } catch (error) {
        //console.log(error);
      }
    };
    getUser();
  }, [id, isAuthenticated]);
  return (
    <React.Fragment>
      <Helmet title="Profile" />

      <Typography variant="h3" gutterBottom display="inline">
        Profile
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>

        <Typography>Profile</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={12} xl={12}>
          <About user={user} />
          <EnhancedTable data={tours} />
          <EnhancedTable1 />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UserProfile;
