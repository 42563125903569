import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
// import Swal from "sweetalert2";
import http from "../../utils/http";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
// import {CircularProgress} from "@mui/material/CircularProgress";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { CloudUpload } from "@mui/icons-material";
import useAppDispatch from "../../hooks/useAppDispatch";
import { EditUser } from "../../redux/slices/user";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  phone: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  companyName: Yup.string().required("Required"),
  // age: Yup.string().required("Required"),
});

function BasicForm({ user, submitted }) {
  // const { reInitialize } = useAuth();
  const [imageData, setImageData] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    companyName: "",
    image: "",
    // password: "mypassword123",
    // confirmPassword: "mypassword123",
  });

  const appDispatch = useAppDispatch();
  useEffect(() => {
    // checking while dispatching and if user null
    if (user && Object.keys(user).length > 0) {
      const tempInitialValues = {};
      Object.keys(initialValues).forEach((item) => {
        tempInitialValues[item] = user[item];
      });
      setInitialValues(tempInitialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  let UserImage = user.image;
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    //console.log(values);
    try {
      if (imageData) {
        //console.log("Commming to the Inage area", imageData);
        try {
          const formData = new FormData();
          formData.append("file", imageData);
          const { data } = await http("/file/upload", {
            method: "POST",
            data: formData,
            // // data: newValues,
            // headers: { "Content-Type": "application/json" },
          });
          values.image = `https://s3.amazonaws.com/image.thiscvr.com/${data.originalname}`;
          console.log(data);
        } catch (error) {
          //console.log(error);
        }
      } else {
        //console.log("Commming to the else area");

        values.image = UserImage;
      }
      appDispatch(EditUser({ values }));
      // if (submitted.submitted) {
      //   Swal.fire({
      //     icon: "success",
      //     title: "Success",
      //     text: "Data updated succesfully",
      //   });
      //   setStatus({ sent: true });
      //   setSubmitting(false);
      // }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Edit Profile
            </Typography>
            {/* <Typography variant="body2" gutterBottom>
              Formik example with Yup validation
            </Typography> */}

            {submitted.submitted && (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Full Name"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  {/* <Grid item md={6}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid> */}
                </Grid>

                <TextField
                  name="email"
                  label="Email"
                  value={values.email}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  // onChange={handleChange}
                  type="email"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="phone"
                  label="Phone"
                  value={values.phone}
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="address"
                  label="Address"
                  value={values.address}
                  error={Boolean(touched.address && errors.address)}
                  fullWidth
                  helperText={touched.address && errors.address}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="companyName"
                  label="Company Name"
                  value={values.companyName}
                  error={Boolean(touched.companyName && errors.companyName)}
                  fullWidth
                  helperText={touched.companyName && errors.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                />
                <Grid item md={4}>
                  <TextField
                    style={{ display: "none" }}
                    id="image"
                    name="image"
                    error={Boolean(touched.image && errors.image)}
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      setImageData(event.currentTarget.files[0]);
                    }}
                  />

                  <label htmlFor="image">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <CloudUpload mr={2} /> Upload Profile Photo
                    </Button>
                    <Typography variant="caption" display="block" gutterBottom>
                      For best results, use an image at least 128px by 128px in
                      .jpg format
                    </Typography>
                  </label>
                </Grid>
                {/* <TextField
                  name="age"
                  label="Image URL"
                  value={values.age}
                  error={Boolean(touched.age && errors.age)}
                  fullWidth
                  helperText={touched.age && errors.age}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  variant="outlined"
                  my={2}
                /> */}

                {/* <TextField
                  name="password"
                  label="Password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  my={2}
                />

                <TextField
                  name="confirmPassword"
                  label="Confirm password"
                  value={values.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  variant="outlined"
                  my={2}
                /> */}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save changes
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function EditProfile() {
  const { user } = useAuth();
  //console.log(user);

  const submitted = useSelector((state) => state.user);
  //console.log(submitted);
  return (
    <React.Fragment>
      {user ? (
        <>
          <Helmet title="Edit Profile" />
          <Typography variant="h3" gutterBottom display="inline">
            Edit Profile
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/pages/profile">
              Dashboard
            </Link>
            {/* <Link component={NavLink} to="/pages/profile">
              Forms
            </Link> */}
            <Typography>Edit Profile</Typography>
          </Breadcrumbs>

          <Divider my={6} />
          <BasicForm user={user} submitted={submitted} />
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );
}

export default EditProfile;
