import axios from "axios";

//console.log({
//   baseURL: process.env.REACT_APP_BASE_API_URL,
// });

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});
// axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

http.interceptors.response.use(
  (response) => response,
  (error) => {
    //console.log(error);
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong2"
    );
  }
);

// const signIn = async () => {
//   const response = await axiosInstance.post("/auth/login", {
//     email: "abc@email.com",
//     password: "123123",
//   });
//   //console.log("axios sign in", response);
// };

// signIn();

// export default axios;

export const setToken = (accessToken) => {
  accessToken = localStorage.getItem("accessToken");
  //console.log({ accessToken });
  http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  //console.log("SETTTEEEDDD", http.defaults.headers.common.Authorization);
  // http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default http;
