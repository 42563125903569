import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

// import Swal from "sweetalert2";
import http from "../../../utils/http";
import useAuth from "../../../hooks/useAuth";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fab as MuiFab,
  Autocomplete,
  Chip,
} from "@mui/material";

import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";

import { DatePicker } from "@mui/x-date-pickers";

import { spacing } from "@mui/system";
// import generateInvoice from "../../../utils/generateInvoice";
import useAppDispatch from "../../../hooks/useAppDispatch";
import {
  selectInvoice,
  editInvoice,
  getInvoice,
} from "../../../redux/slices/invoice";
import { fetchBanks, selectBank } from "../../../redux/slices/bank";
import Swal from "sweetalert2";

const Fab = styled(MuiFab)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

function BasicForm({ banks, invoice = {} }) {
  const [isPaid, setIsPaid] = useState(false);
  // const { reInitialize } = useAuth();
  const validationSchema = Yup.object().shape({
    fromId: Yup.string().required("Required"),
    fromName: Yup.string().required("Required"),
    fromEmail: Yup.string().required("Required"),
    fromAddress: Yup.string().required("Required"),
    fromPhone: Yup.string().required("Required"),
    toId: Yup.array().required("Required"),
    toName: Yup.array().required("Required"),
    toEmail: Yup.array().required("Required"),
    toAddress: Yup.string().required("Required"),
    toPhone: Yup.string().required("Required"),
    date: Yup.mixed().required("Required"),
    tax: Yup.number().min(0).max(100).required("Required"),
    ...(isPaid && { paymentDate: Yup.mixed().required("Required") }),
    ...(isPaid && { paymentReference: Yup.string().required("Required") }),
    ...(isPaid && { paymentMode: Yup.string().required("Required") }),
    ...(isPaid && { paymentAmount: Yup.number().required("Required") }),
    invoiceFor: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
    items: Yup.array().of(
      Yup.object().shape({
        desc: Yup.string().required("desc Required"),
        rate: Yup.number().min(0).required("desc Required"),
        quantity: Yup.number().min(0).required("desc Required"),
        // amount: Yup.number().min(0).required("desc Required"),
      })
    ),
  });

  const appDispatch = useAppDispatch();
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [subtotalAmount, setSubTotalAmount] = React.useState(0);
  const [optionUsers, setOptionUsers] = useState([]);
  const reduxStatus = useSelector(selectInvoice).status;

  const [initialValues, setInitialValues] = useState({
    fromId: "",
    fromName: "",
    fromEmail: "",
    fromAddress: "",
    fromPhone: "",
    toId: [],
    toName: [],
    toEmail: [],
    toAddress: "",
    toPhone: "",
    date: "",
    invoiceFor: "",
    status: "",
    items: [{ desc: "", rate: 0, quantity: 0, amount: 0 }],
    selectedBanks: [],
    paymentDate: "",
    paymentReference: "",
    paymentMode: "",
    paymentAmount: 0,
    tax: 0,
  });

  //console.log("initialValues", initialValues);

  //console.log("invoice", invoice);

  useEffect(async () => {
    // checking while dispatching and if user null
    const tempInitialValues = { ...initialValues };
    //console.log("tempInitialValues", tempInitialValues);

    if (invoice && Object.keys(invoice).length > 0) {
      tempInitialValues.fromId = invoice.fromId;
      tempInitialValues.fromName = invoice.fromName;
      tempInitialValues.fromEmail = invoice.fromEmail;
      tempInitialValues.fromAddress = invoice.fromAddress;
      tempInitialValues.fromPhone = invoice.fromPhone;
      tempInitialValues.toId = invoice.to.map((user) => user.toId);
      tempInitialValues.toName = invoice.to.map((user) => user.toName);
      //console.log("TOOOOOO", tempInitialValues.toName);

      tempInitialValues.toEmail = invoice.to.map((user) => user.toEmail);
      tempInitialValues.toAddress = invoice.toAddress;
      tempInitialValues.toPhone = invoice.toPhone;
      // API does not contain selectedBanks attribute
      //console.log(invoice.selectBank);
      tempInitialValues.selectedBanks = invoice.selectedBanks;
      tempInitialValues.date = invoice.date;
      tempInitialValues.invoiceFor = invoice.invoiceFor;
      tempInitialValues.status = invoice.status;
      tempInitialValues.items = invoice.items;
      tempInitialValues.tax = invoice.tax;
      //console.log("TEMP VALUES", tempInitialValues);

      setInitialValues(tempInitialValues);
    }
    if (invoice.status === "paid") {
      setIsPaid(true);
      tempInitialValues.paymentAmount = invoice.paymentInfo.amount;
      tempInitialValues.paymentMode = invoice.paymentInfo.paymentMethod;
      tempInitialValues.paymentDate = invoice.paymentInfo.paymentDate;
      tempInitialValues.paymentReference = invoice.paymentInfo.paymentReference;
    } else {
      setIsPaid(false);
      tempInitialValues.paymentAmount = 0;
      tempInitialValues.paymentMode = "";
      tempInitialValues.paymentDate = "";
      tempInitialValues.paymentReference = "";
    }
    //console.log("tempInitialValues", tempInitialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  const handleUserChChange = async (e) => {
    if (!e.target.value) return;

    try {
      const { value, name } = e.target;
      //console.log(name, value);
      const { data } = await http.post("/auth/users/byName", {
        ch: value,
      });
      //console.log(data.users);

      let tempUsers = [];
      if (data.users.length)
        tempUsers = data.users.map((u) => {
          u.label = u.name;
          return u;
        });

      setOptionUsers(tempUsers);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setValue, setErrors, setStatus, setSubmitting }
  ) => {
    //console.log("EDITED", values);
    const valuesClone = JSON.parse(JSON.stringify(values));
    const length = valuesClone.toEmail.length;
    const to = [];
    for (let i = 0; i < length; i++) {
      to[i] = {};
      to[i].toName = valuesClone.toName[i];
      to[i].toEmail = valuesClone.toEmail[i];
      to[i].toId = valuesClone.toId[i];
    }

    delete valuesClone.toEmail;
    delete valuesClone.toName;
    valuesClone.toAddress = values.toAddress[0];
    valuesClone.toId = values.toId[0];
    valuesClone.toPhone = values.toPhone[0];
    valuesClone.to = to;

    if (isPaid) {
      valuesClone.paymentInfo = {
        paymentReference: values.paymentReference,
        paymentDate: values.paymentDate,
        paymentMethod: values.paymentMode,
        amount: values.paymentAmount,
      };
      delete valuesClone.paymentAmount;
      delete valuesClone.paymentDate;
      delete valuesClone.paymentMode;
      delete valuesClone.paymentReference;
    }
    //console.log("SUBMITVALUESS", valuesClone);

    // generateInvoice(values, dateValue, totalAmount);

    try {
      appDispatch(editInvoice({ ...valuesClone, _id: invoice._id }));

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Invoice edited successfully",
      });
      setStatus({ sent: true });
    } catch (error) {
      //console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Something went wrong",
      });
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handlePaid = (status) => {
    if (status === "paid") {
      setIsPaid(true);
    } else {
      setIsPaid(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            {/* <Typography variant="h6" gutterBottom>
              Edit Invoice
            </Typography> */}

            {status && status.sent && (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            )}
            {/* {status && !status.sent && (
              <Alert severity="error" my={3}>
                Something went wrong!!!
              </Alert>
            )} */}

            {/* {//console.log(errors)} */}
            {/* {//console.log("VALUES", values)} */}

            {reduxStatus === "loading" ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid>
                  {/* First Row */}

                  {/* Second Row */}

                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="h4">From</Typography>
                      <TextField
                        name="fromName"
                        label="Name"
                        value={values.fromName}
                        error={Boolean(touched.fromName && errors.fromName)}
                        fullWidth
                        helperText={touched.fromName && errors.fromName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                      <TextField
                        name="fromEmail"
                        label="Email"
                        value={values.fromEmail}
                        error={Boolean(touched.fromEmail && errors.fromEmail)}
                        fullWidth
                        helperText={touched.fromEmail && errors.fromEmail}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                      <TextField
                        name="fromAddress"
                        label="Address"
                        value={values.fromAddress}
                        error={Boolean(
                          touched.fromAddress && errors.fromAddress
                        )}
                        fullWidth
                        helperText={touched.fromAddress && errors.fromAddress}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                      <TextField
                        name="fromPhone"
                        label="Phone"
                        value={values.fromPhone}
                        error={Boolean(touched.fromPhone && errors.fromPhone)}
                        fullWidth
                        helperText={touched.fromPhone && errors.fromPhone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                      <Box my={2}>
                        <FormControl
                          style={{ maxWidth: "300px", width: "100%" }}
                        >
                          <InputLabel id="selectedBanks">
                            Select a Bank
                          </InputLabel>
                          <Select
                            style={{ maxWidth: "300px", width: "100%" }}
                            labelId="selectedBanks"
                            id="selectedBanks"
                            name="selectedBanks"
                            value={values.selectedBanks}
                            label="Invoice For"
                            onChange={handleChange}
                            multiple
                            renderValue={(selected) => {
                              const selected1 = banks.filter((bank) => {
                                return selected.includes(bank._id);
                              });
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected1.map((bank) => (
                                    <Chip
                                      key={bank._id}
                                      label={bank.financial_institution}
                                    />
                                  ))}
                                </Box>
                              );
                            }}
                          >
                            {banks.map((b, i) => (
                              <MenuItem key={i} value={b._id}>
                                {b.financial_institution}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h4">To</Typography>
                      <Grid my={2}>
                        <Autocomplete
                          // defaultValue={() => {
                          //   //console.log("----VALUES---at toName field", values);
                          //   return values.toName;
                          // }}
                          value={values.toName}
                          // defaultValue={["Musaib", "musaib Again"]}
                          multiple
                          disabled
                          onChange={(e, value) => {
                            //console.log("VALUETTTT", value);
                            setFieldValue("toId", value?._id);
                            setFieldValue("toName", value?.name);
                            setFieldValue("toEmail", value?.email);
                            setFieldValue("toAddress", value?.address);
                            setFieldValue("toPhone", value?.phone);
                          }} // prints the selected value
                          disablePortal
                          id="combo-box-demo"
                          options={optionUsers}
                          renderInput={(params) => (
                            <TextField
                              name="toName"
                              onChange={handleUserChChange}
                              {...params}
                              label="Select User"
                              type="text"
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <TextField
                        name="toEmail"
                        label="Email"
                        disabled
                        value={values.toEmail}
                        error={Boolean(touched.toEmail && errors.toEmail)}
                        fullWidth
                        helperText={touched.toEmail && errors.toEmail}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                      <TextField
                        name="toAddress"
                        label="Address"
                        disabled
                        value={values.toAddress}
                        error={Boolean(touched.toAddress && errors.toAddress)}
                        fullWidth
                        helperText={touched.toAddress && errors.toAddress}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                      <TextField
                        name="toPhone"
                        label="Phone"
                        disabled
                        value={values.toPhone}
                        error={Boolean(touched.toPhone && errors.toPhone)}
                        fullWidth
                        helperText={touched.toPhone && errors.toPhone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                  <Divider my={4} />

                  {/* Third Row */}

                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <Grid item sx={6} my={4}>
                        <DatePicker
                          label="Invoice Date"
                          value={values.date}
                          onChange={(newValue) => {
                            // setDateValue(newValue);
                            setFieldValue("date", newValue);
                            //console.log("date", typeof newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item sx={6} my={4}>
                        <FormControl
                          my={2}
                          m={2}
                          style={{ maxWidth: "300px", width: "100%" }}
                        >
                          <InputLabel id="invoiceFor">
                            Invoice For (Period)
                          </InputLabel>
                          <Select
                            my={2}
                            style={{ maxWidth: "300px", width: "100%" }}
                            labelId="invoiceFor"
                            id="invoiceFor"
                            name="invoiceFor"
                            value={values.invoiceFor}
                            label="Invoice For"
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>One Time</MenuItem>
                            <MenuItem value={7}>Weekly</MenuItem>
                            <MenuItem value={30}>Monthly</MenuItem>
                            <MenuItem value={365}>Yearly</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item sx={6} my={4}>
                        <FormControl
                          m={2}
                          style={{ maxWidth: "300px", width: "100%" }}
                        >
                          <InputLabel id="status">Payment Status</InputLabel>
                          <Select
                            style={{ maxWidth: "300px", width: "100%" }}
                            labelId="status"
                            id="status"
                            name="status"
                            value={values.status}
                            label="Invoice For"
                            onChange={(e) => {
                              handlePaid(e.target.value);
                              return handleChange(e);
                            }}
                          >
                            <MenuItem value={"notPaid"}>Not Paid</MenuItem>
                            <MenuItem value={"awating"}>Awating</MenuItem>
                            <MenuItem value={"paid"}>Paid</MenuItem>
                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      {isPaid && (
                        <>
                          <Grid item sx={6} my={4}>
                            <FormControl fullWidth>
                              <InputLabel id="paymentMode">
                                Payment Mode
                              </InputLabel>
                              <Select
                                name="paymentMode"
                                label="Payment Mode"
                                labelId="paymentMode"
                                value={values.paymentMode}
                                error={Boolean(
                                  touched.paymentMode && errors.paymentMode
                                )}
                                helperText={
                                  touched.paymentMode && errors.paymentMode
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                              >
                                <MenuItem value={"bankTransfer"}>
                                  Bank Transfer
                                </MenuItem>
                                <MenuItem value={"cheque"}>Cheque</MenuItem>
                                <MenuItem value={"cash"}>Cash</MenuItem>
                                {/* <MenuItem value={"other"}>Other</MenuItem> */}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item sx={6} my={4}>
                            <TextField
                              name="paymentReference"
                              label="Payment Reference"
                              value={values.paymentReference}
                              error={Boolean(
                                touched.paymentReference &&
                                errors.paymentReference
                              )}
                              fullWidth
                              helperText={
                                touched.paymentReference &&
                                errors.paymentReference
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item sx={6} my={2}>
                            <DatePicker
                              label="Payment Date"
                              name="paymentDate"
                              value={values.paymentDate}
                              error={Boolean(
                                touched.paymentDate && errors.paymentDate
                              )}
                              helperText={
                                touched.paymentDate && errors.paymentDate
                              }
                              onChange={(newValue) => {
                                setFieldValue("paymentDate", newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item sx={6} my={4}>
                            <TextField
                              name="paymentAmount"
                              label="Payment Amount"
                              value={values.paymentAmount}
                              error={Boolean(
                                touched.paymentAmount && errors.paymentAmount
                              )}
                              fullWidth
                              helperText={
                                touched.paymentAmount && errors.paymentAmount
                              }
                              type="number"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Divider my={4} />

                  {/* Forth Row */}

                  <FormControl m={2}>
                    {/* <InputLabel id="items">Item Table</InputLabel> */}

                    <FieldArray name="items">
                      {(itemTableProps) => {
                        const { push, remove, form } = itemTableProps;
                        const { items } = form.values;
                        // const setAmount = (index) => {
                        //   setFieldValue(`items.[${index}].amount`, 30);
                        // };
                        return (
                          <div>
                            {items.map((item, index) => {
                              return (
                                <Grid container spacing={6} key={index}>
                                  <Grid item md={1}>
                                    <Fab
                                      mx={2}
                                      size="small"
                                      color="secondary"
                                      aria-label="Add"
                                      onClick={() => {
                                        if (items.length <= 1) return;
                                        remove(index);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </Fab>
                                  </Grid>
                                  <Grid item md={5}>
                                    <TextField
                                      name={`items[${index}].desc`}
                                      label="Description"
                                      value={values.items[index].desc}
                                      error={Boolean(
                                        // touched.items &&
                                        // touched.items[index].desc &&
                                        errors.items &&
                                        errors.items[index]?.desc
                                      )}
                                      fullWidth
                                      helperText={
                                        touched[`items[${index}].desc`] &&
                                        errors[`items[${index}].desc`]
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      variant="outlined"
                                      my={2}
                                    />
                                  </Grid>
                                  <Grid item md={2}>
                                    <TextField
                                      name={`items[${index}].rate`}
                                      label="Rate"
                                      value={values.items[index].rate}
                                      error={Boolean(
                                        // touched.items &&
                                        // touched.items[index].rate &&
                                        errors.items &&
                                        errors.items[index]?.rate
                                      )}
                                      fullWidth
                                      helperText={
                                        touched[`items[${index}].rate`] &&
                                        errors[`items[${index}].rate`]
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      variant="outlined"
                                      type="number"
                                      my={2}
                                    />
                                  </Grid>
                                  <Grid item md={2}>
                                    <TextField
                                      name={`items[${index}].quantity`}
                                      label="Quantity"
                                      value={values.items[index].quantity}
                                      error={Boolean(
                                        // touched.items &&
                                        // touched.items[index].quantity &&
                                        errors.items &&
                                        errors.items[index]?.quantity
                                      )}
                                      fullWidth
                                      helperText={
                                        touched[`items[${index}].quantity`] &&
                                        errors[`items[${index}].quantity`]
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      variant="outlined"
                                      type="number"
                                      my={2}
                                    />
                                  </Grid>
                                  <Grid item md={2}>
                                    <TextField
                                      name={`items[${index}].amount`}
                                      label="Amount"
                                      value={
                                        values["items"][`${index}`].rate *
                                        values["items"][`${index}`].quantity
                                      }
                                      error={Boolean(
                                        errors.items &&
                                        errors.items[index]?.amount
                                      )}
                                      fullWidth
                                      helperText={
                                        touched[`items[${index}].amount`] &&
                                        errors[`items[${index}].amount`]
                                      }
                                      // onBlur={handleBlur}
                                      // onChange={handleChange}
                                      variant="outlined"
                                      type="number"
                                      disabled
                                      my={2}
                                    />
                                  </Grid>
                                </Grid>
                              );
                            })}
                            <Fab
                              mx={2}
                              size="small"
                              color="secondary"
                              aria-label="Add"
                              onClick={() => {
                                push({
                                  desc: "",
                                  rate: 0,
                                  quantity: 0,
                                  amount: 0,
                                });
                              }}
                            >
                              <AddIcon />
                            </Fab>
                          </div>
                        );
                      }}
                    </FieldArray>
                  </FormControl>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "2vw",
                  }}
                >
                  {/* <TextField
                    name={`itemsI`}
                    label="Amount"
                    value={values.items.reduce((prev, cur) => {
                      //console.log(prev.amount);
                      return prev + cur.amount;
                    }, 0)}
                    variant="outlined"
                    type="number"
                    disabled
                    my={2}
                  /> */}

                  <Typography variant="h4">
                    {/* Setting Total amount */}
                    {setSubTotalAmount(
                      values.items.reduce((prev, cur) => {
                        let rate = 0;
                        let quantity = 0;
                        if (cur.rate && !isNaN(cur.rate) && cur.rate > 0)
                          rate = cur.rate;
                        if (
                          cur.quantity &&
                          !isNaN(cur.quantity) &&
                          cur.quantity > 0
                        )
                          quantity = cur.quantity;

                        const sum = rate * quantity;
                        return prev + sum;
                      }, 0)
                    )}
                    {/* Total Amount: {values.selectedBanks?.currency || " $"} */}
                    SubTotal Amount: {" $"}
                    {subtotalAmount.toFixed(2)}
                  </Typography>
                </Box>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "2vw",
                  }}
                >
                  <TextField
                    name="tax"
                    label="Tax"
                    type="number"
                    // disabled
                    style={{ maxWidth: "200px", width: "100%" }}
                    value={values.tax}
                    error={Boolean(touched.tax && errors.tax)}
                    fullWidth
                    helperText={touched.tax && errors.tax}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                {/* Total Field */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "2vw",
                  }}
                >
                  <Typography variant="h4">
                    {/* Setting Total amount */}
                    {setTotalAmount(
                      (
                        subtotalAmount +
                        subtotalAmount * (values.tax * 0.01)
                      ).toFixed(2)
                    )}
                    Total Amount: {values.selectedBanks?.currency || " $"}
                    {totalAmount}
                  </Typography>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save changes
                </Button>
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

function EditInvoice() {
  const appDispatch = useAppDispatch();
  const { banks } = useSelector(selectBank); //fetching the list of banks from redux store
  const { invoice } = useSelector(selectInvoice); //fetching the list of invoice from redux store
  const { user } = useAuth();
  const { id } = useParams();

  useEffect(() => {
    appDispatch(fetchBanks());
    appDispatch(getInvoice(id));
  }, [appDispatch, id]);

  return (
    <React.Fragment>
      <Helmet title="Edit Invoice" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Invoice
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Create Invoice
        </Link>
        <Typography>Edit Invoice</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicForm banks={banks} admin={user} invoice={invoice} />
    </React.Fragment>
  );
}

export default EditInvoice;
