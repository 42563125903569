import axios from "axios";

//console.log("Make Call to axios");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});
// axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    //console.log(error);
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong2"
    );
  }
);

// const signIn = async () => {
//   const response = await axiosInstance.post("/auth/login", {
//     email: "abc@email.com",
//     password: "123123",
//   });
//   //console.log("axios sign in", response);
// };

// signIn();

// export default axios;
export default axiosInstance;
