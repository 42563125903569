import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
// import { useTranslation } from "react-i18next";
import { NavLink, Link as LinkRRD } from "react-router-dom";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  // Card as MuiCard,
  // CardContent,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
} from "@mui/material";

// import Actions from "./Actions";
import BarChart from "./BarChart";
// import LineChart from "./LineChart";
// import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import Table from "./Table";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { useSelector } from "react-redux";
import {
  fetchUsers,
  fetchUsersbyYear,
  selectUser,
} from "../../../redux/slices/user";
import {
  fetchInvoices,
  fetchInvoicesbymonthsnotpaid,
  fetchInvoicesbymonthspaid,
  selectInvoice,
} from "../../../redux/slices/invoice";
import InvoiceList from "../InvoiceList";
// import { Typography } from "@mui/material";

// const Card = styled(MuiCard)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Dashboard() {
  // const { t } = useTranslation();
  const appDispatch = useAppDispatch();

  const { count, userbyyear } = useSelector(selectUser);
  const { invoices, invoiceCount, invoiceCountnopaid, paid, notpaid } =
    useSelector(selectInvoice);
  // const [paidInvoice, setPaidInvoice] = useState(0);
  // const [unpaidInvoice, setunpaidInvoice] = useState(0);

  useEffect(() => {
    appDispatch(fetchUsers("a"));
    appDispatch(fetchUsersbyYear("a"));
    appDispatch(fetchInvoices());
    appDispatch(fetchInvoicesbymonthspaid());
    appDispatch(fetchInvoicesbymonthsnotpaid());
  }, [appDispatch]);

  // useEffect(() => {
  //   const totalinvoicePaid = () => {
  //     let sum = 0;
  //     let totalInvoice = 0;
  //     invoices.map((item) => {
  //       //console.log(item);
  //       if (item.status === "paid") {
  //         sum += item.total;
  //         setPaidInvoice(sum);
  //       } else {
  //         totalInvoice += item.total;
  //         setunpaidInvoice(totalInvoice);
  //       }
  //     });
  //   };
  //   totalinvoicePaid();
  // }, [appDispatch]);

  // const [StatsValue, setStatsValue] = useState({
  //   TotalCustomer: 0,
  // });
  // setStatsValue({ ...StatsValue, TotalCustomer: users.length });
  return (
    <React.Fragment>
      <Helmet title="Admin Dashboard" />
      <Typography variant="h3" gutterBottom display="inline">
        Admin Dashboard
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <LinkRRD
          style={{
            margin: 0,
            color: "#407ad6",
            textDecoration: "none",
          }}
          to="/admin/profile"
        >
          Admin
        </LinkRRD>
        <Typography>Admin Dashboard </Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Customer"
            amount={count || 0}
            // chip="Today"
            percentagetext="+26%"
            percentagecolor={green[500]}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Total Invoice"
            amount={invoices.length || 0}
            // chip="Annual"
            percentagetext="-14%"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Paid Invoice"
            amount={"$" + paid.toFixed(2) || "$ 0"}
            // chip="Monthly"
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Unpaid Invoice"
            amount={"$" + notpaid.toFixed(2) || "$ 0"}
            // chip="Yearly"
            percentagetext="-9%"
            percentagecolor={red[500]}
            // illustration="/static/img/illustrations/waiting.png"
          />
        </Grid>
      </Grid>
      <Grid item sm={6} mb={6} xs={18}>
        <LinkRRD style={{ color: "white" }} to={`/admin/all-users`}>
          <Typography>View all users</Typography>
        </LinkRRD>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <BarChart
            title="Monthly Customers"
            invoicebyYear={userbyyear}
            status="No of Users"
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <BarChart
            title="Monthly Invoices"
            invoicebyYear={invoiceCount}
            invoiceCountnopaid={invoiceCountnopaid}
            status="Paid"
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Table invoices={invoices} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Dashboard;
