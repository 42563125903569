import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import http from "../../../utils/http";

import {
    Grid,
    Link,
    Button,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    TextField,
    Stack,
    Box,
    CircularProgress,
    Alert,
    Card,
    CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { CloudUpload } from "@mui/icons-material";
import { selectTour, getAdminTour } from "../../../redux/slices/tour";
import Swal from "sweetalert2";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const validationSchema = Yup.object().shape({
    shortDescription: Yup.string().required("Short description is Required!"),
    folderName: Yup.string().required("Folder Name is Required!"),
});

function EditAdminVT() {
    const appDispatch = useAppDispatch();
    const userList = useSelector((state) => state.user.users); // fetching users list from user slice
    const [optionUsers, setOptionUsers] = useState([]);
    const [initialValues, setInitialValues] = useState({
        title: "",
        shortDescription: "",
        folderName: "",
        coverImage: "",
        tourLink: "",
    });
    const { adminTour } = useSelector(selectTour);
    const { id } = useParams();
    const [imageData, setImageData] = useState("");
    //console.log("optionInvoices", optionInvoices);
    const thiscvrToursBucket = "virtual-tours.thiscvr.com";

    useEffect(() => {
        appDispatch(getAdminTour(id));
    }, [appDispatch, id]);


    useEffect(async () => {
        // checking while dispatching and if user null
        const tempInitialValues = { ...initialValues };


        if (adminTour && Object.keys(adminTour).length > 0) {
            tempInitialValues.title = adminTour.title;
            tempInitialValues.shortDescription = adminTour.shortDescription;
            tempInitialValues.folderName = adminTour.tourLink.split("/")[adminTour.tourLink.split("/").length-1];
            tempInitialValues.coverImage = adminTour.coverImage;
            tempInitialValues.tourLink = adminTour.tourLink;

            setInitialValues(tempInitialValues);
            //console.log("RENDERING");
            // console.log("TOUR", tempInitialValues);
            //console.log("tempInitialValues", tempInitialValues);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminTour]);

    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        //console.log("SUBMIT VALUESS", tourToSend);
        try {
            if (imageData) {
                // console.log("Commming to the Image area", imageData);
                try {
                    const formData = new FormData();
                    formData.append("file", imageData);
                    const { data } = await http("/file/upload", {
                        method: "POST",
                        data: formData,
                        // // data: newValues,
                        // headers: { "Content-Type": "application/json" },
                    });
                    values.coverImage = `https://s3.amazonaws.com/image.thiscvr.com/${data.originalname}`;
                    console.log(data);
                } catch (error) {
                    //console.log(error);
                }
            }

            if (values.folderName) {
                values.tourLink = `http://${thiscvrToursBucket}/${values.folderName ?? ""}`;
            }
            const tourToSend = {
                ...values,
            };
            const response = await http.put("/tours/edit/adminTour/" + adminTour._id, tourToSend);
            if (response) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "ThisCVR VT updated successfuly",
                });
                resetForm();
                setStatus({ sent: true });
                setSubmitting(false);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Something went wrong",
            });
            setStatus({ sent: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <Helmet title="Edit Tours" />
            <Typography variant="h3" gutterBottom display="inline">
                Edit Virtual Tour
            </Typography>
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/admin/our-works">
                    Manage Tours
                </Link>

                <Typography>Edit Virtual Tour</Typography>
            </Breadcrumbs>
            <Divider my={6} />
            <Stack>
                {/* Autocomplete outside of form */}

                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        errors,
                        setFieldValue,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                    }) => (
                        // <Card mb={6}>
                        //   <CardContent>
                        //     <Typography variant="h6" gutterBottom>
                        //       Upload VT
                        //     </Typography>
                        <Card mb={6}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Edit a Virtual Tour
                                </Typography>
                                {status && status.sent && (
                                    <Alert severity="success" my={3}>
                                        Your data has been submitted successfully!
                                    </Alert>
                                )}

                                {isSubmitting ? (
                                    <Box display="flex" justifyContent="center" my={6}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={4}>
                                            <Grid item container spacing={4}>
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        name="title"
                                                        label="Title"
                                                        value={values.title}
                                                        error={Boolean(touched.title && errors.title)}
                                                        fullWidth
                                                        helperText={touched.title && errors.title}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        variant="outlined"
                                                        my={2}
                                                    />
                                                </Grid>

                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        name="shortDescription"
                                                        label="Short Description"
                                                        value={values.shortDescription}
                                                        error={touched.shortDescription && errors.shortDescription}
                                                        fullWidth
                                                        helperText={touched.shortDescription && errors.shortDescription}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        variant="outlined"
                                                        my={2}
                                                    />
                                                </Grid>
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        name="folderName"
                                                        label="Folder Name"
                                                        value={values.folderName}
                                                        error={touched.folderName && errors.folderName}
                                                        fullWidth
                                                        helperText={touched.folderName && errors.folderName}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        variant="outlined"
                                                        my={2}
                                                    />

                                                    <Typography style={{ fontSize: "14px", marginTop: "8px" }} variant="caption" display="block" gutterBottom>
                                                        Tour link will be: {`${thiscvrToursBucket}/${values.folderName ?? ""}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid fullWidth item md={4}>
                                                <TextField
                                                    style={{ display: "none" }}
                                                    id="coverImage"
                                                    name="coverImage"
                                                    error={Boolean(touched.coverImage && errors.coverImage)}
                                                    accept="image/*"
                                                    type="file"
                                                    fullWidth
                                                    onChange={(event) => {
                                                        setImageData(event.currentTarget.files[0]);
                                                    }}
                                                />

                                                <label htmlFor="coverImage">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                        fullWidth
                                                    >
                                                        <CloudUpload mr={2} /> Upload Cover Photo
                                                    </Button>
                                                    <Typography variant="caption" display="block" gutterBottom>
                                                        For best results, use an image at least 128px by 128px in
                                                        .jpg format
                                                    </Typography>
                                                </label>
                                            </Grid>
                                            <Grid item container spacing={4}>
                                                <Grid item sm={6} xs={12}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        mt={3}
                                                        sx={"300px"}
                                                    >
                                                        Save changes
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </CardContent>
                        </Card>
                    )}
                </Formik>
            </Stack>
        </React.Fragment>
    );
}

export default EditAdminVT;
