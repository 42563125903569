import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Link,
  Popover,
  Typography,
  Snackbar,
  Card as MuiCard,
  CardHeader,
  Chip as MuiChip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../../hooks/useAppDispatch";
import useAuth from "../../../hooks/useAuth";
import { fetchTours, selectTour } from "../../../redux/slices/tour";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import {
  ContentCopy,
  Email,
  Facebook,
  Share,
  Code,
  WhatsApp,
} from "@mui/icons-material";
import { Helmet } from "react-helmet-async";

/////////////////////////////////////////////////////
import { getUserAnalytics } from "../../../redux/slices/useranalysis";
import { useSelector, useDispatch } from "react-redux";
import { Navigation } from "react-feather";
/////////////////////////////////////////////////////
import styled from "@emotion/styled";

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-item: center;
  padding: 1rem;
  gap: 4px;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #eeee;
`;

function Tours() {
  const report = useSelector((state) => state.useranalytics);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserAnalytics());
  }, []);

  // Popup
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // Popup

  //Code Modal
  // const openCodeModal = (tourLink) => {
  //   copy(tourLink);
  // };
  //Code Modal
  const appDispatch = useAppDispatch();
  const { tours } = useSelector(selectTour);
  const { isAuthenticated } = useAuth();

  const url = [];
  const data = [];

  for (var i = 0; i < tours.length; i++) {
    url.push(`${tours[i]["subdomain"]}.thiscvr.com`);
  }

  if (!report.loading) {
    var reportAnalytics = report?.analytics ?? [];

    for (var i = 0; i < url.length; i++) {
      let dataAnalytics = {};
      for (var j = 0; j < reportAnalytics.length; j++) {
        let dimensionValues = reportAnalytics[j].dimensionValues ?? [];
        if (dimensionValues.length > 0 && url[i] === dimensionValues[0].value) {
          dataAnalytics = reportAnalytics[j]
        }
      }
      data.push(dataAnalytics);
    }
  }

  // State to Show Data is Copied
  const [openclip, setOpenclip] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);

  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    appDispatch(fetchTours());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + ":" : "00:";
    var mDisplay = m > 0 ? (m > 10 ? m + ":" : "0" + m + ":") : "00:";
    var sDisplay = s > 0 ? (s > 10 ? s + "" : "0" + s) : "00";
    return hDisplay + mDisplay + sDisplay;
  }

  // console.log("tous", tours);
  // console.log(report, data);

  if (report.loading) {
    return (
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
        my={6}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet title="Virtual Tours" />

        {tours.length ? (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h2" mb={2}>
                Virtual Tours
              </Typography>
            </Grid>

            {/* Showing the tours in map inside a card  */}
            {data.length > 0 && tours.map((tour, index) => {
              const tourLink = `${url[index]}/${tour.folderName}`;
              return <Grid key={index} item xs={12}>
                {/* making the flex display of the tour discription  */}
                <CardWrapper>
                  {/* A container showing the details of the Tour  */}
                  <Grid item xs={12}>
                    {/* Title of the Tour  */}
                    <Typography
                      variant="h3"
                      align="center"
                      mb={2}
                      style={{
                        color: "white",
                        textTransform: "capitalize",
                      }}
                    >
                      {tour.title}
                    </Typography>
                    {/* Tour created time  */}
                    <Typography
                      variant="p"
                      mb={1}
                      style={{
                        color: "white",
                      }}
                    >
                      Created At: {tour.time.slice(0, 10)}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ position: "relative" }}>
                    {tour ? (
                      <Box height={"400px"}>
                        <iframe
                          id={`iframe-${index}`}
                          title={tour.title}
                          // src={
                          //   "http://tour.thiscvr.com/tour/ShangriLa/index.htm"
                          // }
                          src={`https://${tour["subdomain"]}.thiscvr.com/${tour["folderName"]}`}
                          style={{
                            border: 0,
                            width: "100%",
                            height: "100%",
                            color: "white",
                            backgroundColor: "#787a89",
                            borderRadius: "5px",
                          }}
                          allowFullScreen=""
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent={"center"} my={6}>
                        <CircularProgress />
                      </Box>
                    )}
                    <Button
                      aria-describedby={id}
                      variant="contained"
                      onClick={(e) => {
                        setSelectedTour(tour);
                        handleClick(e);
                      }
                      }
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        zIndex: "1",
                      }}
                    >
                      <Share />
                    </Button>
                  </Grid>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography
                      sx={{
                        p: 2,
                      }}
                    >
                      <Link
                        href={!selectedTour ? "" : `https://api.whatsapp.com/send?phone=&text=https://${selectedTour["subdomain"]}.thiscvr.com/${selectedTour["folderName"]}`}
                        color="inherit"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                        target="_blank"
                      >
                        <WhatsApp /> WhatsApp
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        p: 2,
                      }}
                    >
                      <Link
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                          !selectedTour ? "" : `https://${selectedTour["subdomain"]}.thiscvr.com/${selectedTour["folderName"]}`
                        )}`}
                        color="inherit"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                        target="_blank"
                      >
                        <Facebook /> Facebook
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        p: 2,
                      }}
                    >
                      <Link
                        href={`mailto:?subject=Virtual%20Tour&body=Virtual%20Tour%20Name%3A${encodeURI(
                          !selectedTour ? "" : selectedTour.title
                        )}%0aVirtual%20Tour%20Link%3A${encodeURI(
                          !selectedTour ? "" : `https://${selectedTour["subdomain"]}.thiscvr.com/${selectedTour["folderName"]}`
                        )}`}
                        color="inherit"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                        target="_blank"
                      >
                        <Email /> Email
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        p: 2,
                      }}
                    >
                      <Link
                        color="inherit"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "0.5rem",
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                        // data-iframeId={`iframe-${index}`}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            !selectedTour ? "" : `https://${selectedTour["subdomain"]}.thiscvr.com/${selectedTour["folderName"]}`

                          );
                          setOpenclip(true);
                        }}
                      >
                        <ContentCopy /> Copy Link
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        p: 2,
                      }}
                    >
                      <Link
                        color="inherit"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "0.5rem",
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                        // data-iframeId={`iframe-${index}`}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            !selectedTour ? "" :
                              `<iframe id="iframe-${index}" title="${selectedTour.title}" src="https://${selectedTour["subdomain"]}.thiscvr.com/${selectedTour["folderName"]}" style="border: 0; width: 100%; height: 100%; color: white; background-color: #787a89; border-radius: 5px;" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>`

                          );
                          setOpenclip(true);
                        }}
                      >
                        <Code /> Copy Embedded HTML
                      </Link>
                    </Typography>
                  </Popover>
                  <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={openclip}
                    onClose={() => {
                      setOpenclip(false);
                    }}
                    message="Copied to Clipboard!"
                    key={vertical + horizontal}
                  />

                  <Grid xs={12} style={{ overflowX: "scroll" }}>
                    {/* Showing the data of the User Tour Analytics  */}

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Tour</TableCell>
                          <TableCell align="center">Page Views</TableCell>
                          <TableCell align="center">Users</TableCell>
                          <TableCell align="center">Sessions</TableCell>
                          <TableCell align="center">Time On Page</TableCell>
                          <TableCell align="center">View Analysis</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.length > 0 && <TableRow>
                          <TableCell component="th" scope="row">
                            {tourLink}
                          </TableCell>
                          <TableCell align="center">{data[index].metricValues?.length > 0 ? data[index]?.metricValues[0].value ?? 0 : 0}</TableCell>
                          <TableCell align="center">{data[index].metricValues?.length > 1 ? data[index]?.metricValues[1].value ?? 0 : 0}</TableCell>
                          <TableCell align="center">{data[index].metricValues?.length > 3 ? data[index]?.metricValues[3].value ?? 0 : 0}</TableCell>
                          <TableCell align="center">
                            {secondsToHms(
                              parseFloat(data[index]?.metricValues?.length > 6 ? data[index]?.metricValues[6].value ?? 0 : 0).toFixed(2)
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Link
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(
                                  `/virtual-tours/my-tour?value=${tour["subdomain"]}.thiscvr.com`
                                );
                              }}
                            >
                              <Navigation />
                            </Link>
                          </TableCell>
                        </TableRow>}
                      </TableBody>
                    </Table>
                  </Grid>
                </CardWrapper>
              </Grid>
            })}
          </Grid>
        ) : (
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100%"}
            my={6}
          >
            <CircularProgress />
          </Box>
        )}
      </React.Fragment>
    );
  }
}

export default Tours;
