import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
// import { useSelector } from "react-redux";

import {
  Alert as MuiAlert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  // Card as MuiCard,
  Card,
  CardContent,
  CircularProgress,
  Divider as MuiDivider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
  // Fab as MuiFab,
} from "@mui/material";

import { spacing } from "@mui/system";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { selectBank, getBank, editBank } from "../../../redux/slices/bank";
import { useSelector } from "react-redux";

// const Fab = styled(MuiFab)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

// const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

// creating validation schema
const validationSchema = Yup.object().shape({
  financial_institution: Yup.string().required("Required"),
  beneficiary_name: Yup.string().required("Required"),
  beneficiary_account: Yup.number().required("Required"),
  beneficiary_address: Yup.string().required("Required"),
  swift_code: Yup.string().required("Required"),
  branch_transit_number: Yup.string().required("Required"),
  pay_to_bank: Yup.string().required("Required"),
  // currency: Yup.string().required("Required"),
});

// form to add banks
function BasicForm() {
  const appDispatch = useAppDispatch();
  const { bank } = useSelector(selectBank);
  const { id } = useParams();
  //console.log("bank", bank);
  const [initialValues, setInitialValues] = useState({
    financial_institution: "RBC Royal Bank (Barbados) Limited",
    beneficiary_name: "ThisCVR",
    beneficiary_account: "094253004272",
    beneficiary_address: "#62 Waterhall Terrace St. James Barbados",
    swift_code: "ROYCBBBB",
    branch_transit_number: "09425",
    pay_to_bank: "RBC Royal Bank (Barbados) Limited Bridgetown, Barbados",
    // currency: "",
  });

  useEffect(() => {
    appDispatch(getBank(id));
  }, [appDispatch, id]);

  useEffect(() => {
    const tempInitialValues = { ...initialValues };
    Object.keys(tempInitialValues).forEach((item) => {
      tempInitialValues[item] = bank[item];
    });
    setInitialValues(tempInitialValues);
    //console.log(tempInitialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank]);

  // sending the bank details to redux store
  const handleSubmit = async (values) => {
    //console.log(values);
    appDispatch(editBank({ ...values, _id: bank._id }));
    // setOpen(false); // closing the add bank form once submitted successfully
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Add New Invoice
              </Typography>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  Your data has been submitted successfully!
                </Alert>
              )}

              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Grid container>
                    <Grid item container spacing={4}>
                      <Grid item md={6} my={2}>
                        <TextField
                          name="financial_institution"
                          label="Financial Institution"
                          value={values.financial_institution}
                          error={Boolean(
                            touched.financial_institution &&
                            errors.financial_institution
                          )}
                          fullWidth
                          helperText={
                            touched.financial_institution &&
                            errors.financial_institution
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      <Grid item md={6} my={2}>
                        <TextField
                          name="beneficiary_name"
                          label="Beneficiary Name"
                          value={values.beneficiary_name}
                          error={Boolean(
                            touched.beneficiary_name && errors.beneficiary_name
                          )}
                          fullWidth
                          helperText={
                            touched.beneficiary_name && errors.beneficiary_name
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={4}>
                      <Grid item md={6} my={2}>
                        <TextField
                          name="beneficiary_account"
                          label="Beneficiary Account"
                          value={values.beneficiary_account}
                          error={Boolean(
                            touched.beneficiary_account &&
                            errors.beneficiary_account
                          )}
                          fullWidth
                          helperText={
                            touched.beneficiary_account &&
                            errors.beneficiary_account
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} my={2}>
                        <TextField
                          name="beneficiary_address"
                          label="Beneficiary Address"
                          value={values.beneficiary_address}
                          error={Boolean(
                            touched.beneficiary_address &&
                            errors.beneficiary_address
                          )}
                          fullWidth
                          helperText={
                            touched.beneficiary_address &&
                            errors.beneficiary_address
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={4}>
                      <Grid item md={6} my={2}>
                        <TextField
                          name="swift_code"
                          label="Swift Code"
                          value={values.swift_code}
                          error={Boolean(
                            touched.swift_code && errors.swift_code
                          )}
                          fullWidth
                          helperText={touched.swift_code && errors.swift_code}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item md={6} my={2}>
                        <TextField
                          name="branch_transit_number"
                          label="Branch Transit Number"
                          value={values.branch_transit_number}
                          error={Boolean(
                            touched.branch_transit_number &&
                            errors.branch_transit_number
                          )}
                          fullWidth
                          helperText={
                            touched.branch_transit_number &&
                            errors.branch_transit_number
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container spacing={4}>
                      <Grid item md={6} my={2}>
                        <TextField
                          name="pay_to_bank"
                          label="Pay To Bank"
                          value={values.pay_to_bank}
                          error={Boolean(
                            touched.pay_to_bank && errors.pay_to_bank
                          )}
                          fullWidth
                          helperText={touched.pay_to_bank && errors.pay_to_bank}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          my={2}
                        />
                      </Grid>

                      {/* <Grid item md={6} my={4}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="currency">Select Currency</InputLabel>
                          <Select
                            style={{ width: "100%" }}
                            labelId="currency"
                            id="currency"
                            name="currency"
                            value={values.currency}
                            label="Invoice For"
                            onChange={handleChange}
                          >

                            <MenuItem value="$">USD</MenuItem>
                            <MenuItem value="€">EUR</MenuItem>
                            <MenuItem value="Bds$">Barbados Dollar</MenuItem>
                            <MenuItem value="Can$">Canadian Dollar</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> */}
                    </Grid>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                    >
                      Save changes
                    </Button>
                  </Grid>
                </form>
              )}
            </CardContent>
          </Card>
        )}
      </Formik>
    </Box>
  );
}
function EditBank() {
  // const { user } = useAuth();

  return (
    <React.Fragment>
      <Helmet title="Add New Invoice" />
      <Typography variant="h3" gutterBottom display="inline">
        Edit Bank
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Manage Banks
        </Link>
        <Typography>Edit Bank</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <BasicForm />
    </React.Fragment>
  );
}

export default EditBank;
