import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import http from "../../../utils/http";
import { CloudUpload } from "@mui/icons-material";

import {
    Grid,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Divider as MuiDivider,
    Typography,
    TextField,
    Stack,
    Button,
    Box,
    CircularProgress,
    Alert,
    Card,
    CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { fetchUsers } from "../../../redux/slices/user";
import Swal from "sweetalert2";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const validationSchema = Yup.object().shape({
    shortDescription: Yup.string().required("Short description is Required!"),
    folderName: Yup.string().required("Folder Name is Required!"),
});

function CreateOurWork() {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    // const { users } = useSelector(selectUser);
    // const [userCh, setUserCh] = useState(null);
    const [optionUsers, setOptionUsers] = useState([]);
    const [imageData, setImageData] = useState("");
    const [optionInvoices, setOptionInvoices] = useState([]);
    // const {users} = useAuth()

    useEffect(() => {
        appDispatch(fetchUsers());
    }, [appDispatch]);

    const thiscvrToursBucket = "virtual-tours.thiscvr.com";



    //console.log(optionInvoices);
    const handleSubmit = async (
        values,
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true);
            if (imageData) {
                // console.log("Commming to the Image area", imageData);
                try {
                    const formData = new FormData();
                    formData.append("file", imageData);
                    const { data } = await http("/file/upload", {
                        method: "POST",
                        data: formData,
                        // // data: newValues,
                        // headers: { "Content-Type": "application/json" },
                    });
                    values.coverImage = `https://s3.amazonaws.com/image.thiscvr.com/${data.originalname}`;
                    console.log(data);
                } catch (error) {
                    //console.log(error);
                }
            }
            if (values.folderName) {
                values.tourLink = `http://${thiscvrToursBucket}/${values.folderName ?? ""}`;
            }
            const response = await http.post(`/tours/adminVT`, values);
            if (response) {
                //console.log({ response });
                Swal.fire({
                    icon: "success",
                    title: response.data.msg || "Tour Created Successfully!",
                    html: `<a target='__blank' href="https://s3.console.aws.amazon.com/s3/buckets/${thiscvrToursBucket}?region=us-east-1&tab=objects">Click Here</a> to Upload the files on AWS.`,
                });
                navigate("/admin/our-works", { replace: true });
                setSubmitting(false);
            }
            //console.log(response);
        } catch (error) {
            //console.log({ error });
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error?.error?.message || error.msg || "Something went wrong",
            });
            setSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <Helmet title="Create Virtual Tours" />
            <Typography variant="h3" gutterBottom display="inline">
                Create Our Work
            </Typography>
            <Breadcrumbs
                aria-label="Breadc
      rumb"
                mt={2}
            >
                <Link component={NavLink} to="/admin/our-works">
                    Our Works
                </Link>
                <Typography>Create Our Work</Typography>
            </Breadcrumbs>
            <Divider my={6} />
            <Stack>
                {/* Autocomplete outside of form */}
                <Formik
                    enableReinitialize
                    initialValues={{
                        title: "",
                        shortDescription: "",
                        folderName: "",
                        coverImage: "",
                        tourLink: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        status,
                    }) => (
                        <Card mb={6}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom pb={4}>
                                    Create Our Work
                                </Typography>
                                {status && status.sent && (
                                    <Alert severity="success" my={3}>
                                        Your data has been submitted successfully!
                                    </Alert>
                                )}

                                {isSubmitting ? (
                                    <Box display="flex" justifyContent="center" my={6}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={4}>
                                            <Grid item container spacing={4}>
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        name="title"
                                                        label="Title"
                                                        value={values.title}
                                                        error={Boolean(touched.title && errors.title)}
                                                        fullWidth
                                                        helperText={touched.title && errors.title}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        variant="outlined"
                                                        my={2}
                                                    />
                                                </Grid>

                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        name="shortDescription"
                                                        label="Short Description"
                                                        value={values.shortDescription}
                                                        error={touched.shortDescription && errors.shortDescription}
                                                        fullWidth
                                                        helperText={touched.shortDescription && errors.shortDescription}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        variant="outlined"
                                                        my={2}
                                                    />
                                                </Grid>
                                                <Grid item md={12} xs={12}>
                                                    <TextField
                                                        name="folderName"
                                                        label="Folder Name"
                                                        value={values.folderName}
                                                        error={touched.folderName && errors.folderName}
                                                        fullWidth
                                                        helperText={touched.folderName && errors.folderName}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        type="text"
                                                        variant="outlined"
                                                        my={2}
                                                    />

                                                    <Typography style={{ fontSize: "14px", marginTop: "8px" }} variant="caption" display="block" gutterBottom>
                                                        Tour link will be: {`${thiscvrToursBucket}/${values.folderName ?? ""}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid fullWidth item md={4}>
                                                <TextField
                                                    style={{ display: "none" }}
                                                    id="coverImage"
                                                    name="coverImage"
                                                    error={Boolean(touched.coverImage && errors.coverImage)}
                                                    accept="image/*"
                                                    type="file"
                                                    fullWidth
                                                    onChange={(event) => {
                                                        setImageData(event.currentTarget.files[0]);
                                                    }}
                                                />

                                                <label htmlFor="coverImage">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                        fullWidth
                                                    >
                                                        <CloudUpload mr={2} /> Upload Cover Photo
                                                    </Button>
                                                    <Typography variant="caption" display="block" gutterBottom>
                                                        For best results, use an image at least 128px by 128px in
                                                        .jpg format
                                                    </Typography>
                                                </label>
                                            </Grid>
                                            <Grid item container spacing={4}>
                                                <Grid item sm={6} xs={12}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        mt={3}
                                                        sx={"300px"}
                                                    >
                                                        Save changes
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </CardContent>
                        </Card>
                    )}
                </Formik>
            </Stack>
        </React.Fragment>
    );
}

export default CreateOurWork;
