import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

// const menus = [
//   { label: "Details", link: "/" },
//   { label: "Edit", link: "/" },
//   { label: "Delete", link: "/" },
// ];

const ThreeDotMenu = ({ menus }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menus.map((m, i) => (
          <MenuItem key={i} href={m.link} onClick={handleClose}>
            {m.label !== "Delete" && (
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to={m.link}
              >
                {m.label}
              </Link>
            )}
            {m.label === "Delete" && (
              <div onClick={() => m.delete()}>{m.label}</div>
            )}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
};

export default ThreeDotMenu;
