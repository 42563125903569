import { createContext, useEffect, useReducer } from "react";
import Swal from "sweetalert2";

import emailjs from "@emailjs/browser";

// import axios from "../utils/axios";
import http, { setToken } from "../utils/http";
import { isValidToken, setSession } from "../utils/jwt";
import { Navigate, useNavigate } from "react-router-dom";
import ResetPasswordNew from "../components/auth/ResetPasswordNew";
import useAppDispatch from "../hooks/useAppDispatch";
import { fetchUsers } from "../redux/slices/user";
import { fetchTours } from "../redux/slices/tour";
import { fetchBanks } from "../redux/slices/bank";
import { fetchInvoices } from "../redux/slices/invoice";

// import useAppDispatch from "../hooks/useAppDispatch";
// import { initialize, selectJWT } from "../redux/slices/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const LOADING = "LOADING";
const NOTLOADING = "NOTLOADING";
const ERROR = "ERROR";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {},
  loading: false,
  error: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case LOADING:
      return {
        loading: true,
      };
    case NOTLOADING:
      return {
        loading: false,
      };
    case ERROR:
      return {
        loading: false,
        user: {},
        error: action.payload,
        isAuthenticated: false,
      };

    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        error: null,
      };

    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        loading: false,
        error: null,
      };

    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loading: false,
        error: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const appDispatch = useAppDispatch();
  useEffect(() => {
    const initialize = async () => {
      // console.log("Reinitialized page auth");
      try {
        load();
        const accessToken = window.localStorage.getItem("accessToken");
        // console.log(accessToken);
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          // console.log("Token is valid");

          // setToken();

          const response = await http.get("/auth/user");
          const { user } = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: populateUserWithImg(user),
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: {},
            },
          });
        }
      } catch (err) {
        // console.error("ERROR", err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: {},
          },
        });
      }
      // navigate("/auth/sign-out", { replace: true });
    };

    initialize();
  }, []);

  const load = () => {
    dispatch({
      type: LOADING,
    });
  };

  const notLoad = () => {
    dispatch({
      type: NOTLOADING,
    });
  };

  const populateUserWithImg = (user) => {
    if (user.file && user?.file?.type === "Buffer")
      user.file = Buffer.from(user.file).toString("base64");

    return user;
  };

  const reInitialize = async () => {
    load();
    const response = await http.get("/auth/user");
    const { user, accessToken } = response.data;
    setSession(accessToken);
    //console.log("ACCESSTOKEN", accessToken);

    dispatch({
      type: INITIALIZE,
      payload: {
        isAuthenticated: true,
        user: populateUserWithImg(user),
      },
    });
  };
  useEffect(() => {
    if (initialState.isAuthenticated) {
      appDispatch(fetchUsers);
      appDispatch(fetchTours);
      appDispatch(fetchBanks);
      appDispatch(fetchInvoices);
    }
    //console.log(initialState.isAuthenticated);
  }, [initialState.isAuthenticated]);
  const signIn = async (email, password) => {
    try {
      load();
      const response = await http.post("/auth/login", {
        email,
        password,
      });

      const { accessToken, user, msg } = response.data;

      setSession(accessToken);
      dispatch({
        type: SIGN_IN,
        payload: {
          user: populateUserWithImg(user),
        },
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: msg,
      });

      if (user?.role === "admin")
        navigate("/admin/dashboard", { replace: true });
      else navigate("/pages/profile", { replace: true });
    } catch (error) {
      //console.log(error);
      dispatch({ type: ERROR, payload: { user: {}, error } });
      // Swal.fire({
      //   icon: "error",
      //   title: "Error",
      //   text: error.msg,
      // });
      throw error;
    }
  };

  const signOut = async () => {
    load();
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (
    name,
    email,
    companyName,
    phone,
    address,
    img,
    password
  ) => {
    load();
    const response = await http.post("/api/auth/sign-up", {
      name,
      email,
      companyName,
      phone,
      address,
      img,
      password,
    });

    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = async ({ otp, password, email }) => {
    //console.log(otp + password + email);
    try {
      load();
      const { data } = await http.post("/auth/reset-password", {
        email,
        otp,
        password,
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: data.msg,
      });
      navigate("/auth/sign-in");
    } catch (error) {
      //console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.msg,
      });
    } finally {
      notLoad();
    }
  };

  const forgotPassword = async (email) => {
    try {
      load();
      const { data } = await http.post("/auth/forget-password", { email });
      const { user } = data;
      // await sendEmail(user);

      Swal.fire({
        icon: "success",
        title: "Success",
        text: data.msg,
      });

      navigate("/auth/reset-password-new", { state: user });
    } catch (error) {
      //console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.msg,
      });
    } finally {
      notLoad();
    }
  };

  const sendEmail = async ({ name, email, resetKey }) => {
    const templateParams = {
      name,
      to: email,
      link: `${window.location.origin}/auth/reset-password-new?resetKey=${resetKey}`,
    };
    const res = await emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );

    //console.log(res);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
        forgotPassword,
        reInitialize,
        load,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
